import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { ProviderService } from '../../catalog/provider/provider.service';
import { ProviderModel } from '../../common/models/Provider.Model';
import { ItemService} from '../../catalog/item/item.service';
import { Item } from '../../common/models/Item.Model';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { DatePipe } from '@angular/common';
import { ReportsService } from '../reports.service';
import { MiscellaneousService } from '../../miscellaneous/miscellaneous.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';

@Component({
  selector: 'app-equipmentio',
  templateUrl: './equipmentio.component.html',
  styleUrls: ['./equipmentio.component.scss']
})
export class EquipmentioComponent implements OnInit {

  constructor(
    private globalFunctionService: GlobalFunctionsService,
    private settings: SettingsService,
    private providerServices: ProviderService,
    private ItemsServices: ItemService,
    private reportService: ReportsService,
    private miscellaneousService: MiscellaneousService,

  ) { }

  usrrec: string = "";
  usrreg: string = "";
  hasta: string = "";
  desde: string = "";
  working: boolean = false;
  status: StatusDTO[] = [];
  tipos: StatusDTO[] = [];
  providers: ProviderModel[] = [];
  items: Item[] = [];

  inputArgumentProvider: string = 'providerName';
  inputArgumentItems: string = 'ItemDescription';

  comprobante: string = "";
  orden: string = "";
  pedido: string = "";
  colaborador: string = "";
  statusSelected: string = "0";
  tiposSelected: string = "0";
  selectedProvider: ProviderModel;
  selectedItem: Item;
  userLogin: UserLogin;
  datepipe: DatePipe = new DatePipe('en-US');
  TipoSelected: string = "0";
  downloading: boolean = false;

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    this.globalFunctionService.canOperate();
    this.desde = new Date().toISOString().split('T')[0];
    this.hasta = new Date().toISOString().split('T')[0];

    this.desde = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.hasta = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.LoadData();
    this.getProviders();
    this.getItems();
  }


  changeDate(initial: boolean) {

  }


  getProviders() {
    this.providerServices
      .getProviderList()
      .toPromise()
      .then((resp: ProviderModel[]) => {
        let ut = new ProviderModel();
        ut.providerId = 0;

        ut.providerName = "Todos";
        this.providers.push(ut);
        resp.map((x) => {
          {

            this.providers.push(x);
          }
        });
        let findProvider = this.providers.find(x => x.providerId == 0);
        this.selectedProvider = findProvider;


      })
      .catch();
  }

  getItems() {
    this.ItemsServices
      .GetItems()
      .toPromise()
      .then((resp: Item[]) => {
        let ut = new Item();
        ut.ItemId = 0;

        ut.ItemDescription = "Todos";
        this.items.push(ut);
        resp.map((x) => {
          {

            this.items.push(x);
          }

        });
        let findItem = this.items.find(x => x.ItemId == 0);
        this.selectedItem = findItem;

      })
      .catch();
  }

  selectProviderEvent(value: any): void {

    this.selectedProvider = value;

  }

  selectItemEvent(value: any): void {

    this.selectedItem = value;

  }

  download() {

    if (this.usrreg == null)
      this.usrreg = "";

    if (this.usrrec == null)
      this.usrrec = "";

    if (this.colaborador == null)
      this.colaborador = "";

    if (this.pedido == null)
      this.pedido = "";

    if (this.comprobante == null)
      this.comprobante = "";

    if (this.orden == null)
      this.orden = "";
      this.downloading = true;


      let f1 = new Date(this.desde);
      let f2 = new Date(this.hasta);
      if (f1.toISOString() >  f2.toISOString()) {
        this.globalFunctionService.messageToast(`Error La fecha desde no puede ser mayor a la fecha hasta, verifique.`, 'error');
        this.downloading = false;
        return;
      }

      const selectedeq = typeof this.selectedItem.ItemId === 'number' && !isNaN(this.selectedItem.ItemId);
      const selectedprov = typeof this.selectedProvider.providerId === 'number' && !isNaN(this.selectedProvider.providerId);
      if (!selectedeq) {
        this.globalFunctionService.messageToast(`Debe seleccionar un Equipo o Todos antes de continuar.`, 'warning');
        this.downloading = false;
        return;
      }

      if (!selectedprov) {
        this.globalFunctionService.messageToast(`Debe seleccionar un Proveedor o Todos antes de continuar.`, 'warning');
        this.downloading = false;
        return;
      }

    this.reportService.getRepEquipmentIOExcel(this.usrreg,
                                              this.usrrec,
                                              this.colaborador,
                                              this.pedido,
                                              this.comprobante,
                                              this.orden,
                                              this.TipoSelected,
                                              this.selectedItem.ItemId.toString(),
                                              this.desde,
                                              this.hasta,
                                              this.statusSelected,
                                              this.selectedProvider.providerId.toString()
                                            ).subscribe((resp: any) => {

      if (resp == null) {
        this.downloading = false;
        this.globalFunctionService.messageToast(`No se ha encontrado información.`, 'warning');
        return;
      }
      console.log("termino llamada");

      let blob = new Blob([resp], { type: resp.type });

      const anchor = document.createElement('a');

      anchor.download = `CEQ-EquipmentIO.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
      this.downloading = false;

    }, err => {
      this.downloading = false;
      this.globalFunctionService.messageToast(`Error en la invocación.`, 'warning');
      console.log(err);
    });



  }


  LoadData() {
    this.status.push({ Id: "0", Description: "Todos" });
    this.status.push({ Id: "1", Description: "Registrado" });
    this.status.push({ Id: "2", Description: "Verificar Datos" });
    this.status.push({ Id: "3", Description: "Baja de Equipo" });
    this.status.push({ Id: "4", Description: "Factura Pendiente" });
    this.status.push({ Id: "5", Description: "Cancelado" });
    this.getStatusList();


    this.tipos.push({ Id: "0", Description: "Todos" });
    this.tipos.push({ Id: "1", Description: "Compras" });
    this.tipos.push({ Id: "2", Description: "Recuperacion de seguridad" });
    this.tipos.push({ Id: "3", Description: "Cobro Colaborador" });
    this.tipos.push({ Id: "4", Description: "Perdida Fortuita" });
    this.tipos.push({ Id: "5", Description: "Otras Bajas" });
    this.tipos.push({ Id: "6", Description: "Bajas por Manejo" });
    this.tipos.push({ Id: "7", Description: "Misceláneos" });

    this.TipoSelected = "0";
  }

  getStatusList(){
    this.miscellaneousService
      .GetMiscellaneousStatus()
      .toPromise()
      .then((resp: string[]) => {
        let id = Math.max(...this.status.map(o => Number(o.Id)))
        id++;
        resp.map(x=>{
          if(!this.status.find(s => x == s.Description)){
            this.status.push({ Id: id.toString(), Description: x });
            id++;
          }
        });
        console.log(this.status);
      })
      .catch((err) => {
        console.log(err);
      });
  }

}



export class StatusDTO {
  Id: string;
  Description: string;

}
