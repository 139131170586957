import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capex-import',
  templateUrl: './capex-import.component.html',
  styleUrls: ['./capex-import.component.scss']
})
export class CapexImportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
