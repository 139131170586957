import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { DatePipe } from '@angular/common';
import { Ceve } from '../../../common/models/Ceve.Model';
import { CeveCut } from '../../../common/models/CeveCut.Model';
import { CeveCutDetail } from 'src/app/ceq/common/models/CeveCutDetail.Model';
import { CeveCutLineType } from 'src/app/ceq/common/models/CeveCutEnum.Model';
import { CeveCutStatus } from 'src/app/ceq/common/models/CeveCutStatus.Enum';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { InventoryCutService } from '../inventory-cut.service';
import Swal from 'sweetalert2';
import { Calendar } from 'src/app/ceq/common/models/Calendar.Model';
import { CalendarService } from 'src/app/ceq/configuration/calendar/calendar.service';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { HttpErrorResponse } from '@angular/common/http';
import { InventoryMovement } from 'src/app/ceq/common/models/InventoryMovement.Model';
import { InventoryMovementDetail } from 'src/app/ceq/common/models/InventoryMovementDetail.Model';
import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';

interface movObj {
  Movement: InventoryMovement;
  Details: Array<InventoryMovementDetail>;
}

@Component({
  selector: 'app-inventory-ceve',
  templateUrl: './inventory-ceve.component.html',
  styleUrls: ['./inventory-ceve.component.scss'],
})
export class InventoryCeveComponent implements OnInit {
  constructor(
    private router: Router,
    private calendarServ: CalendarService,
    private settings: SettingsService,
    private inventoryCutService: InventoryCutService,
    private globalFunctionService: GlobalFunctionsService
  ) {}

  //Visuales:
  firstChangeVersion: Date = new Date('2022-10-09'); //version de inventario inicial, entradas y salidas del SP7. Ultimo inventario antes de esta version: 8 de octubre
  secondChangeVersion: Date = new Date('2022-10-29'); //version de inventario inicial, entradas y salidas del SP8. Ultimo inventario antes de esta version: 5 de noviembre
  thirdChangeVersion: Date = new Date('2023-05-10'); //version de inventario inicial, entradas y salidas del SP9. Ultimo inventario antes de esta version: 10 de Mayo
  chargedIsCollapsed: boolean = false;

  //Property
  cutVersion: number = 1;
  datepipe: DatePipe = new DatePipe('en-US');
  unitId: number;
  ceveCutId: string;
  userLogin: UserLogin;
  hasSelected: boolean;
  ceve: Ceve[] = [new Ceve()];
  actualDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  selectedDate: string;
  previousDate: string;
  startDate = this.datepipe.transform(
    new Date().setDate(new Date().getDate() - 40),
    'yyyy-MM-dd'
  );
  keyword = 'CeveName';
  signs: {
    dispatcherReciverSign: string;
    managerSignature: string;
    supervisorSignature: string;
    dispatcherReciverComment: string;
    managerComment: string;
    supervisorComment: string;
  };
  isDispatcherReciverSign: boolean;
  isManagerSign: boolean;
  isSupervisorSign: boolean;
  signCompleted: boolean;
  isSaving: boolean;
  disableButton: boolean;
  isLoading: boolean;
  currentYear = new Date().getFullYear();
  listDateAvalible: Calendar[] = [];
  lastEvent: Calendar;
  status: CeveCutStatus;
  isCeveAdmn: boolean;
  isCeveSupervisor: boolean;
  actualDateTime: string;
  isDateTimeRangeValid: boolean = false;
  asSum: any[] = [];
  isNodeChild: boolean = false;
  isNodeLeader: boolean = false;
  modifiedAt: string;

  //signsActivities
  signActDispatcherReceiver: boolean = false;
  signActAdministrator: boolean = false;
  signActSuppervisorDisp: boolean = false;

  persistentCreatedAt: Date = undefined;
  persistentCreatedBy: string = '';

  //Product type titles
  containerData: Array<{
    ContainerId: string;
    ContainerDescription: string;
    ContainerName: string;
  }> = [];

  //set arrays at one form
  inventoryForm = new FormGroup({
    physicalInventory: new FormArray([]),
    physicalInventoryfromLapsed: new FormArray([]),
    couponInventory: new FormArray([]),
    promissoryNoteInventory: new FormArray([]),
    totalInventoryVehicle: new FormArray([]),
    rtmVehicles: new FormArray([]),
    routeCharges: new FormArray([]),
    stockAllocation: new FormArray([]),
    vanLoadRejection: new FormArray([]),
    totalPhysicalInventory: new FormArray([]),
    logisticsBalance: new FormArray([]),
    diffNotAttributable: new FormArray([]),
    diffAttributable: new FormArray([]),
  });

  inventoryArray = [
    { name: 'asVehicle', value: [] },
    { name: 'transit', value: [] },
    { name: 'losses', value: [] },
    { name: 'securityRecovery', value: [] },
    { name: 'chargedToCollaborator', value: [] },
    { name: 'valeChofer', value: [] },
    { name: 'valeColabs', value: [] },
    { name: 'compulsas', value: [] },
    { name: 'paybillAS', value: [] },
    { name: 'supposedInventory', value: [] },
    { name: 'previousInventory', value: [] },
    { name: 'inputMovements', value: [] },
    { name: 'outputMovements', value: [] },
    { name: 'invAvailableCalculated', value: [] },
    { name: 'transitLeader', value: [] },
    { name: 'closingInventory', value: [] },
    { name: 'currentComodato', value: [] },
    { name: 'expiredComodato', value: [] },
    { name: 'expiredChargedComodato', value: [] },
    { name: 'miscellaneous', value: [] },
    { name: 'adjustCeve', value: [] },
    { name: 'totalDifferences', value: [] },
  ];

  //#region Access to formarray
  //Acces to each formarray without use the form
  get physicalInventory(): FormArray {
    return this.inventoryForm.get('physicalInventory') as FormArray;
  }
  get physicalInventoryfromLapsed(): FormArray {
    return this.inventoryForm.get('physicalInventoryfromLapsed') as FormArray;
  }
  get couponInventory(): FormArray {
    return this.inventoryForm.get('couponInventory') as FormArray;
  }
  get promissoryNoteInventory(): FormArray {
    return this.inventoryForm.get('promissoryNoteInventory') as FormArray;
  }
  get totalInventoryVehicle(): FormArray {
    return this.inventoryForm.get('totalInventoryVehicle') as FormArray;
  }
  get rtmVehicles(): FormArray {
    return this.inventoryForm.get('rtmVehicles') as FormArray;
  }
  get routeCharges(): FormArray {
    return this.inventoryForm.get('routeCharges') as FormArray;
  }
  get stockAllocation(): FormArray {
    return this.inventoryForm.get('stockAllocation') as FormArray;
  }
  get vanLoadRejection(): FormArray {
    return this.inventoryForm.get('vanLoadRejection') as FormArray;
  }
  get totalPhysicalInventory(): FormArray {
    return this.inventoryForm.get('totalPhysicalInventory') as FormArray;
  }
  get logisticsBalance(): FormArray {
    return this.inventoryForm.get('logisticsBalance') as FormArray;
  }
  get diffNotAttributable(): FormArray {
    return this.inventoryForm.get('diffNotAttributable') as FormArray;
  }
  get diffAttributable(): FormArray {
    return this.inventoryForm.get('diffAttributable') as FormArray;
  }
  get chargedToCollaborator(): any[] {
    return this.inventoryArray.find((x) => x.name == 'chargedToCollaborator')
      .value;
  }
  get valeChofer(): any[] {
    return this.inventoryArray.find((x) => x.name == 'valeChofer').value;
  }
  get valeColabs(): any[] {
    return this.inventoryArray.find((x) => x.name == 'valeColabs').value;
  }
  get compulsas(): any[] {
    return this.inventoryArray.find((x) => x.name == 'compulsas').value;
  }
  get paybillAS(): any[] {
    return this.inventoryArray.find((x) => x.name == 'paybillAS').value;
  }
  get supposedInventory(): any[] {
    return this.inventoryArray.find((x) => x.name == 'supposedInventory').value;
  }
  get previousInventory(): any[] {
    return this.inventoryArray.find((x) => x.name == 'previousInventory').value;
  }
  get inputMovements(): any[] {
    return this.inventoryArray.find((x) => x.name == 'inputMovements').value;
  }
  get outputMovements(): any[] {
    return this.inventoryArray.find((x) => x.name == 'outputMovements').value;
  }
  get invAvailableCalculated(): any[] {
    return this.inventoryArray.find((x) => x.name == 'invAvailableCalculated')
      .value;
  }
  get asDriverVehicles(): any[] {
    return this.inventoryArray.find((x) => x.name == 'asDriverVehicle').value;
  }
  get asClients(): any[] {
    return this.inventoryArray.find((x) => x.name == 'asClient').value;
  }
  get transit(): any[] {
    return this.inventoryArray.find((x) => x.name == 'transit').value;
  }
  get transitLeader(): any[] {
    return this.inventoryArray.find((x) => x.name == 'transitLeader').value;
  }
  get losses(): any[] {
    return this.inventoryArray.find((x) => x.name == 'losses').value;
  }
  get securityRecovery(): any[] {
    return this.inventoryArray.find((x) => x.name == 'securityRecovery').value;
  }
  get closingInventory(): any[] {
    return this.inventoryArray.find((x) => x.name == 'closingInventory').value;
  }
  get currentComodato(): any[] {
    return this.inventoryArray.find((x) => x.name == 'currentComodato').value;
  }
  get expiredComodato(): any[] {
    return this.inventoryArray.find((x) => x.name == 'expiredComodato').value;
  }
  get expiredChargedComodato(): any[] {
    return this.inventoryArray.find((x) => x.name == 'expiredChargedComodato').value;
  }
  get miscellaneous(): any[] {
    return this.inventoryArray.find((x) => x.name == 'miscellaneous').value;
  }
  get adjustCeve(): any[] {
    return this.inventoryArray.find((x) => x.name == 'adjustCeve').value;
  }
  get totalDifferences(): any[] {
    return this.inventoryArray.find((x) => x.name == 'totalDifferences').value;
  }

  //#endregion

  ngOnInit(): void {
    this.InititalizeData();
  }

  InititalizeData() {
    this.asSum = [];
    this.isLoading = false;
    this.getEvents();
    this.ceveCutId = '';
    this.signs = {
      dispatcherReciverSign: '',
      managerSignature: '',
      supervisorSignature: '',
      dispatcherReciverComment: '',
      managerComment: '',
      supervisorComment: '',
    };
    this.hasSelected = false;
    this.signCompleted = false;
    this.status = CeveCutStatus.NEW;
    this.userLogin = this.settings.getUser();
    var activities = this.settings.getUserAccess();
    this.isCeveSupervisor = false;
    this.isCeveAdmn = false;
    if (activities.find((x) => x == 'SUPCEVE')) {
      this.isCeveSupervisor = true;
    }
    if (activities.find((x) => x == 'ADMCEVE')) {
      this.isCeveAdmn = true;
    }
    if (activities.includes('FICADRC')) {
      this.signActDispatcherReceiver = true;
    }
    if (activities.includes('FICAACE')) {
      this.signActAdministrator = true;
    }
    if (activities.includes('FICASDC')) {
      this.signActSuppervisorDisp = true;
    }

    this.inventoryCutService
      .getCevesByUser(this.userLogin.Id)
      .subscribe((ceve) => (this.ceve = ceve));
    //initial operations
    this.setControls();
    //subscribers
    this.subscribeControls();
  }

  initControls() {
    this.inventoryForm = new FormGroup({
      physicalInventory: new FormArray([]),
      physicalInventoryfromLapsed: new FormArray([]),
      couponInventory: new FormArray([]),
      promissoryNoteInventory: new FormArray([]),
      totalInventoryVehicle: new FormArray([]),
      rtmVehicles: new FormArray([]),
      routeCharges: new FormArray([]),
      stockAllocation: new FormArray([]),
      vanLoadRejection: new FormArray([]),
      totalPhysicalInventory: new FormArray([]),
      logisticsBalance: new FormArray([]),
      diffNotAttributable: new FormArray([]),
      diffAttributable: new FormArray([]),
    });

    this.inventoryArray = [
      { name: 'asDriverVehicle', value: [] },
      { name: 'asClient', value: [] },
      { name: 'transit', value: [] },
      { name: 'transitLeader', value: [] },
      { name: 'losses', value: [] },
      { name: 'securityRecovery', value: [] },
      { name: 'chargedToCollaborator', value: [] },
      { name: 'valeChofer', value: [] },
      { name: 'valeColabs', value: [] },
      { name: 'compulsas', value: [] },
      { name: 'paybillAS', value: [] },
      { name: 'supposedInventory', value: [] },
      { name: 'previousInventory', value: [] },
      { name: 'inputMovements', value: [] },
      { name: 'outputMovements', value: [] },
      { name: 'invAvailableCalculated', value: [] },
      { name: 'closingInventory', value: [] },
      { name: 'currentComodato', value: [] },
      { name: 'expiredComodato', value: [] },
      { name: 'expiredChargedComodato', value: [] },
      { name: 'miscellaneous', value: [] },
      { name: 'adjustCeve', value: [] },
      { name: 'totalDifferences', value: [] },
    ];
    this.asSum = [];
  }

  //set form array with length of product type array
  setControls() {
    for (var index = 0; index < this.containerData.length; index++) {
      //init for controls
      this.physicalInventory.push(new FormControl(0));
      this.physicalInventoryfromLapsed.push(new FormControl(0));
      this.couponInventory.push(new FormControl(0));
      this.promissoryNoteInventory.push(new FormControl(0));
      // this.othersInventory.push(new FormControl(0));
      this.totalInventoryVehicle.push(new FormControl(0));
      this.rtmVehicles.push(new FormControl(0));
      this.routeCharges.push(new FormControl(0));
      this.stockAllocation.push(new FormControl(0));
      this.vanLoadRejection.push(new FormControl(0));
      this.totalPhysicalInventory.push(new FormControl(0));
      this.logisticsBalance.push(new FormControl('0'));
      this.diffNotAttributable.push(new FormControl(''));
      this.diffAttributable.push(new FormControl(0));

      //init for data
      this.asDriverVehicles.push(0);
      this.asClients.push(0);
      this.transit.push(0);
      this.transitLeader.push(0);
      this.losses.push(0);
      this.securityRecovery.push(0);
      this.chargedToCollaborator.push(0);
      this.valeChofer.push(0);
      this.valeColabs.push(0);
      this.compulsas.push(0);
      this.paybillAS.push(0);
      this.supposedInventory.push(0);
      this.previousInventory.push(0);
      this.inputMovements.push(0);
      this.outputMovements.push(0);
      this.invAvailableCalculated.push(0);
      this.asSum.push(0);
      this.closingInventory.push(0);
      this.currentComodato.push(0);
      this.expiredComodato.push(0);
      this.expiredChargedComodato.push(0);
      this.miscellaneous.push(0);
      this.adjustCeve.push(0);
      this.totalDifferences.push(0);
    }
  }

  getEvents(): void {
    this.calendarServ.getCalendarCutEvents().subscribe(
      (events: Calendar[]) => {
        this.listDateAvalible = events.sort((b,a)=>new Date(a.StartAt).getTime() - new Date(b.StartAt).getTime());
        var dates = events.map((x) => x.StartAt);
        var newDate = dates.reduce((a, b) => (a > b ? a : b));
        this.lastEvent = events.find((x) => x.StartAt == newDate);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async getCentralTime(): Promise<Date> {
    var centralDate: Date = new Date();
    await this.inventoryCutService
      .getCentralTime()
      .toPromise()
      .then((x) => {
        centralDate = x;
      })
      .catch(() => {
        this.globalFunctionService.messageToast(`No se ha podido obtener la hora central.`, 'warning');
        return;
      });
    return centralDate;
  }

  subscribeControls() {
    // this.subscribeNewBalance();
    this.subscribeTotalVehicles();
    this.subscribeTotalPhysicalInventory();
    this.subscribeDifferenceNotAttributable();
    this.subscribeSupposedInventory();
    this.subscribeDiffAttributable();
  }

  //subscribe controls to calculate vehicle inventory
  subscribeTotalVehicles() {
    this.rtmVehicles.valueChanges.subscribe((x) => {
      this.onTotalVehicleChange();
    });
    this.routeCharges.valueChanges.subscribe((x) => {
      this.onTotalVehicleChange();
    });
    this.stockAllocation.valueChanges.subscribe((x) => {
      this.onTotalVehicleChange();
    });
    this.vanLoadRejection.valueChanges.subscribe((x) => {
      this.onTotalVehicleChange();
    });
  }

  //subscribe controls to calcule total physical inventory
  subscribeTotalPhysicalInventory() {
    this.physicalInventory.valueChanges.subscribe((x) => {
      this.onTotalPhysicalInventoryChange();
    });
    this.couponInventory.valueChanges.subscribe((x) => {
      this.onTotalPhysicalInventoryChange();
    });
    this.promissoryNoteInventory.valueChanges.subscribe((x) => {
      this.onTotalPhysicalInventoryChange();
    });
    this.totalInventoryVehicle.valueChanges.subscribe((x) => {
      this.onTotalPhysicalInventoryChange();
    });
  }

  //subscribe controls to calcule diff not imputable to ceve
  subscribeDifferenceNotAttributable() {
    this.logisticsBalance.valueChanges.subscribe((x) => {
      this.onDiffNotAttributableChange();
    });
  }

  //subscribe controls to calculate supposed inventory
  subscribeSupposedInventory() {
    this.logisticsBalance.valueChanges.subscribe((x) => {
      this.onDiffNotAttributableChange();
    });
  }

  subscribeDiffAttributable() {
    this.totalPhysicalInventory.valueChanges.subscribe((x) => {
      this.onDiffAttributableChange();
    });
  }

  //operations for the calculation of total physical inventory
  onTotalPhysicalInventoryChange() {
    let result: Array<number> = [];
    for (var i = 0; i < this.totalPhysicalInventory.length; i++) {
      result.push(
        Number(this.physicalInventory.controls[i].value) +
          Number(this.couponInventory.controls[i].value) +
          Number(this.promissoryNoteInventory.controls[i].value) +
          Number(this.totalInventoryVehicle.controls[i].value) +
          Number(this.paybillAS[i]) +
          Number(this.transit[i]) +
          Number(this.transitLeader[i])
      );
    }
    this.totalPhysicalInventory.setValue(result);
  }

  //operations for the calculation of vehicle inventory
  onTotalVehicleChange() {
    let result: Array<number> = [];
    for (var i = 0; i < this.totalInventoryVehicle.length; i++) {
      result.push(
        Number(this.rtmVehicles.controls[i].value) +
          Number(this.asDriverVehicles[i]) +
          Number(this.asClients[i]) +
          Number(this.routeCharges.controls[i].value) +
          Number(this.currentComodato[i]) +
          Number(this.stockAllocation.controls[i].value) -
          Number(this.vanLoadRejection.controls[i].value) -
          Number(this.expiredComodato[i])
      );
    }
    this.totalInventoryVehicle.setValue(result);
  }

  //operations for the calculate of difference not attributable to ceve
  onDiffNotAttributableChange() {
    let result: Array<number> = [];
    for (let i = 0; i < this.chargedToCollaborator.length; i++) {
      result.push(
        (Number(this.chargedToCollaborator[i]) + Number(this.losses[i])) * -1
      );
    }
    this.diffNotAttributable.setValue(result);
  }

  //operations for the calculate of differential Attributable
  onDiffAttributableChange() {
    let result: Array<number> = [];
    for (let i = 0; i < this.diffAttributable.length; i++) {
      result.push(
        this.status == 'LAPSED'
          ? 0
          : Number(this.totalPhysicalInventory.controls[i].value) -
              (Number(this.supposedInventory[i]) + Number(this.asClients[i]))
      );
    }
    this.diffAttributable.setValue(result);
  }

  //Select new Ceve
  async selectEvent(item?) {
    this.updateTime();
    console.log(await this.getCentralTime());
    console.log(new Date(this.selectedDate));
    console.log(this.actualDate);
    if (new Date(this.selectedDate) < this.firstChangeVersion) {
      this.cutVersion = 0;
    } else if (new Date(this.selectedDate) < this.secondChangeVersion) {
      this.cutVersion = 1;
    } else if(new Date(this.selectedDate) < this.thirdChangeVersion){
      this.cutVersion = 2;
    } else{
      this.cutVersion = 3;
    }
    if (
      (await this.getCentralTime()) < new Date(this.selectedDate) &&
      this.selectedDate == this.lastEvent.StartAt.toString()
    ) {
      Swal.fire({
        icon: 'warning',
        title: `La captura de inventario estará disponible hasta el dia ${
          this.lastEvent.StartAt.toString().split('T')[0]
        } a las ${this.lastEvent.StartAt.toString().split('T')[1]}`,
      });
      return;
    }
    if (
      this.actualDateTime >= this.lastEvent.StartAt.toString() &&
      this.actualDateTime <= this.lastEvent.EndAt.toString() &&
      this.selectedDate == this.lastEvent.StartAt.toString()
    ) {
      this.isDateTimeRangeValid = true;
    } else {
      this.isDateTimeRangeValid = false;
    }

    if (!item) {
      if (this.unitId == undefined) {
        return;
      }
    }
    if (item) {
      this.unitId = item.UnitId;
    }
    if (this.selectedDate == null) {
      return;
    }
     var selectedDateIndex = this.listDateAvalible.indexOf(
       this.listDateAvalible.find(
         (x) => x.StartAt.toString() == this.selectedDate
       )
     );
     this.previousDate =
       selectedDateIndex >= (this.listDateAvalible.length - 1)
         ? (this.datepipe.transform(this.inventoryCutService.getPreviousDate(new Date(this.selectedDate)), 'yyyy-MM-ddTHH:mm'))
         : this.listDateAvalible[selectedDateIndex + 1].StartAt.toString();
    this.isLoading = true;
    this.hasSelected = false;
    this.inventoryCutService
      .isNodeLeader(this.userLogin.Org, this.unitId)
      .subscribe((x: boolean) => {
        this.isNodeLeader = x;
      });
    this.inventoryCutService.getCeveByContainers(this.unitId).subscribe((x) => {
      this.containerData = [];
      x.forEach((element) => {
        this.containerData.push({
          ContainerDescription: element.ContainerDescription.split('.', 1),
          ContainerName: element.ContainerCode,
          ContainerId: element.ContainerId,
        });
      });
      this.inventoryCutService
        .getCeveCut(
          this.unitId,
          this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd')
        )
        .toPromise()
        .then((x) => {
          if (x) {
            this.inventoryCutService
              .isNodeChild(this.userLogin.Org, this.unitId)
              .subscribe((nc: boolean) => {
                this.isNodeChild = nc;
                this.existInventory(x.CeveCutDetail, x.CeveCut);
              });
          } else {
            this.inventoryForm.reset();
            //Validación de centro de ventas activo
            this.inventoryCutService
              .getCeveActive(this.unitId)
              .toPromise()
              .then((isActive) => {
                if (isActive) {
                  //Llama al sp para generar caratula. Si responde Ok se recarga. Si no se manda error
                  if (
                     this.actualDateTime < this.lastEvent.StartAt.toString() &&
                     this.selectedDate == this.lastEvent.StartAt.toString()
                     || !this.isDateTimeRangeValid
                  ) {
                    Swal.fire({
                      icon: 'warning',
                      title:
                        'La captura de inventario no está disponible en este momento',
                    });
                    this.isLoading = false;
                    this.inventoryForm.disable();
                    return;
                  } else {
                    //Mensaje de espera
                    Swal.fire({
                      title: 'Actualizando información...',
                      html: 'Por favor espere',
                      didOpen: () => {
                        Swal.showLoading();
                      },
                    });
                    this.inventoryCutService
                      .generateCeveCutSP(
                        this.unitId,
                        this.previousDate,
                        this.selectedDate
                      )
                      .toPromise()
                      .then((resp) => {
                        Swal.close();
                        this.isLoading = false;
                        this.selectEvent();
                      })
                      .catch((err: HttpErrorResponse) => {
                        console.log(err);
                        Swal.fire({
                          icon: 'error',
                          title: err.error,
                        });
                        return;
                      });
                  }
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: `El nodo seleccionado no se encuentra activo.`,
                  });
                  return;
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  existInventory(ceveCutDetail: Array<CeveCutDetail>, ceveCut: CeveCut) {
    if(ceveCut.Version != undefined && ceveCut.Version != null){
      this.cutVersion = ceveCut.Version;
    }
    this.signs.dispatcherReciverSign = ceveCut.DispatcherReciverSign;
    this.signs.managerSignature = ceveCut.ManagerSign;
    this.signs.supervisorSignature = ceveCut.SupervisorSign;
    this.signs.dispatcherReciverComment = ceveCut.DispatcherReciverComment;
    this.signs.managerComment = ceveCut.ManagerComment;
    this.signs.supervisorComment = ceveCut.SupervisorComment;
    this.isManagerSign = ceveCut.ManagerSign != '';
    this.isDispatcherReciverSign = ceveCut.DispatcherReciverSign != '';
    this.isSupervisorSign = ceveCut.SupervisorSign != '';
    this.signCompleted =
      this.isManagerSign &&
      this.isDispatcherReciverSign &&
      this.isSupervisorSign;
    this.status = ceveCut.Status;
    this.ceveCutId = ceveCut.CeveCutId;
    this.persistentCreatedAt = ceveCut.CreatedAt;
    this.persistentCreatedBy = ceveCut.CreatedBy;
    this.modifiedAt = this.datepipe.transform(
      ceveCut.ModifiedAt,
      'dd/MM/yyyy - HH:mm'
    );
    this.initControls();
    this.setControls();
    this.subscribeControls();
    for (let i = 0; i < ceveCutDetail.length; i++) {
      for (let k = 0; k < this.containerData.length; k++) {
        if (
          ceveCutDetail[i].ContainerId ==
          Number(this.containerData[k].ContainerId)
        ) {
          switch (ceveCutDetail[i].Line) {
            case CeveCutLineType.PhysicalInventory:
              this.physicalInventory.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              this.physicalInventoryfromLapsed.controls[k].setValue(
                ceveCutDetail[i].Quantity < 0 ? 0 : ceveCutDetail[i].Quantity
              );
              break;
            case CeveCutLineType.Coupons:
              this.couponInventory.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
            case CeveCutLineType.PromissoryNote:
              this.promissoryNoteInventory.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case CeveCutLineType.TotalVehicle:
              this.totalInventoryVehicle.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case CeveCutLineType.RtmVehicles:
              this.rtmVehicles.controls[k].setValue(ceveCutDetail[i].Quantity);
              break;
            case CeveCutLineType.RouteCharges:
              this.routeCharges.controls[k].setValue(ceveCutDetail[i].Quantity);
              break;
            case CeveCutLineType.StockAllocation:
              this.stockAllocation.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case CeveCutLineType.VanLoadRejection:
              this.vanLoadRejection.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case CeveCutLineType.TotalPhysicalInventory:
              this.totalPhysicalInventory.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case CeveCutLineType.LogisticsBalance:
              this.logisticsBalance.controls[k].setValue(
                ceveCutDetail[i].Quantity
              );
              break;
            case CeveCutLineType.ChargeToCollaborator:
              this.chargedToCollaborator[k] = ceveCutDetail[i].Quantity;
              break;

            case CeveCutLineType.valeChofer:
              this.valeChofer[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.valeColabs:
              this.valeColabs[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.compulsas:
              this.compulsas[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.paybillAS:
              this.paybillAS[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.DifferenceNotAttributable:
              if (this.cutVersion > 0) {
                this.diffNotAttributable.controls[k].setValue(
                  ceveCutDetail[i].Quantity
                );
              } else {
                this.diffAttributable.controls[k].setValue(
                  ceveCutDetail[i].Quantity
                );
              }
              break;
            case CeveCutLineType.SupposedInventory:
              if (!this.cutVersion) {
                this.invAvailableCalculated[k] = ceveCutDetail[i].Quantity;
              }
              this.supposedInventory[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.DifferenceAttributable:
              if (this.cutVersion > 0) {
                this.diffAttributable.controls[k].setValue(
                  ceveCutDetail[i].Quantity
                );
              } else {
                this.diffNotAttributable.controls[k].setValue(
                  ceveCutDetail[i].Quantity
                );
              }
              break;
            case CeveCutLineType.SecurityRecovery:
              this.securityRecovery[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.Losses:
              this.losses[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.asDriverVehicles:
              this.asDriverVehicles[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.asClients:
              this.asClients[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.Transit:
              this.transit[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.TransitLeader:
              this.transitLeader[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.PreviousInventory:
              this.previousInventory[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.InputMovements:
              this.inputMovements[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.OutputMovements:
              this.outputMovements[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.InvAvailableCalculated:
              this.invAvailableCalculated[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.ClosingInventory:
              this.closingInventory[k] = ceveCutDetail[i].Quantity;
              break;
            case CeveCutLineType.CurrentComodato:
              this.currentComodato[k] = ceveCutDetail[i].Quantity;
              break;
              case CeveCutLineType.ExpiredComodato:
                this.expiredComodato[k] = ceveCutDetail[i].Quantity;
                break;
            case CeveCutLineType.ExpiredChargedComodato:
              this.expiredChargedComodato[k] = ceveCutDetail[i].Quantity;
              break;

            case CeveCutLineType.Miscellaneous:
              this.miscellaneous[k] = ceveCutDetail[i].Quantity;
              break;

            case CeveCutLineType.AdjustCeve:
              this.adjustCeve[k] = ceveCutDetail[i].Quantity;
              break;

            case CeveCutLineType.TotalDifferences:
              this.totalDifferences[k] = ceveCutDetail[i].Quantity;
              break;

            default:
              break;
          }
        }
      }
    }
    this.asSum = [];
    for (let k = 0; k < this.containerData.length; k++) {
      this.asSum.push(this.asDriverVehicles[k] + this.asClients[k]);
    }
    if (
      !this.isCeveAdmn ||
      this.status == CeveCutStatus.CLOSED ||
      this.status == CeveCutStatus.PUBLISHED ||
      this.status == CeveCutStatus.INCOMPLETESIGNED ||
      this.status == CeveCutStatus.LAPSED ||
      this.isNodeChild
    ) {
      this.inventoryForm.disable();
    }
    // this.onLogisticsBalanceSummatory();
    this.onDiffNotAttributableChange();
    this.onDiffAttributableChange();
    this.onTotalVehicleChange();
    this.onTotalPhysicalInventoryChange();
    this.outOfTime();
    if (
      (this.status == CeveCutStatus.CLOSED ||
        this.status == CeveCutStatus.INCOMPLETESIGNED) &&
      this.signCompleted &&
      this.isDateTimeRangeValid
    ) {
      this.publishInventory();
    }
    this.hasSelected = true;
    this.disableButton = true;
    this.isLoading = false;
  }

  async saveOrEdit(close: boolean) {
    this.isSaving = true;
    if (this.status == CeveCutStatus.SAVED) {
      if (close) {
        const confirm = await this.warningApply(
          `¿Desea cerrar el corte?. Los registros no podrán ser editados.`
        );
        if (!confirm) {
          Swal.close();
          this.isSaving = false;
          return;
        }
      } else {
        const confirm = await this.warningApply(`¿Desea guardar el corte?`);
        if (!confirm) {
          Swal.close();
          this.isSaving = false;
          return;
        }
      }
    }
    this.showLoading();
    this.updateTime();
    // if(this.status != CeveCutStatus.LAPSED && this.status != CeveCutStatus.PUBLISHED && this.status != CeveCutStatus.NEW){
    //   if(this.actualDateTime > this.lastEvent.EndAt.toString()){
    //     this.lapsedInventory();
    //     return;
    //   }
    // }
    if (this.status == CeveCutStatus.NEW) {
      this.saveCeveCut();
    } else if (this.status == CeveCutStatus.SAVED) {
      if (close) {
        this.editCeveCut(CeveCutStatus.CLOSED, []);
      } else {
        this.editCeveCut(CeveCutStatus.SAVED, []);
      }
    }
  }

  async openCeveCut() {
    const confirm = await this.warningApply(
      `¿Desea reabrir el corte?. El registro podrá ser editado y las firmas ingresadas se eliminarán.`
    );
    if (!confirm) {
      Swal.close();
      this.isSaving = false;
      return;
    }
    this.showLoading();
    this.updateTime();
    this.isSaving = true;
    this.inventoryCutService
      .openCeveCut(this.ceveCutId)
      .toPromise()
      .then((x) => {
        Swal.close();
        this.selectEvent();
        this.globalFunctionService.messageToast(`Carátula de inventario abierta.`, 'success');
      })
      .catch((error: HttpErrorResponse) => {
        Swal.close();
        this.globalFunctionService.messageToast(`No se pudo abrir el inventario.`, 'error');
        console.log(error.error);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  async editCeveCut(status: CeveCutStatus, signs: any[]) {
    let dataToSave: CeveCut = new CeveCut();
    var userName = this.settings.getUser().Name;
    var stringDate = this.datepipe.transform(
      await this.getCentralTime(),
      'yyy,MM,dd'
    );
    var date = new Date(stringDate);
    dataToSave.Org = this.userLogin.Org;
    dataToSave.UnitId = this.unitId;
    dataToSave.DispatcherReciverSign = this.signs.dispatcherReciverSign;
    dataToSave.ManagerSign = this.signs.managerSignature;
    dataToSave.SupervisorSign = this.signs.supervisorSignature;
    dataToSave.DispatcherReciverComment = this.signs.dispatcherReciverComment;
    dataToSave.ManagerComment = this.signs.managerComment;
    dataToSave.SupervisorComment = this.signs.supervisorComment;
    dataToSave.Status = status;
    dataToSave.CeveCutId = this.ceveCutId;
    dataToSave.ModifiedAt = new Date(this.actualDateTime);
    dataToSave.ModifiedBy = this.userLogin.Name;
    dataToSave.CreatedAt =
      this.persistentCreatedAt != undefined
        ? this.persistentCreatedAt
        : await this.getCentralTime();
    dataToSave.CreatedBy =
      this.persistentCreatedBy != ''
        ? this.persistentCreatedBy
        : this.userLogin.Name;

    if (signs.length > 0) {
      if (signs[0] == 'isDispatcherReciverSign') {
        dataToSave.DispatcherReciverSign = signs[1];
      } else if (signs[0] == 'isManagerSign') {
        dataToSave.ManagerSign = signs[1];
      } else if (signs[0] == 'isSupervisorSign') {
        dataToSave.SupervisorSign = signs[1];
      }
    }

    dataToSave.CeveCutDay = this.datepipe.transform(
      this.selectedDate,
      'yyyy-MM-dd'
    );
    var details = this.setCeveCutDetail(userName, date);
    //send request
    var dataSend: { ceveCut: CeveCut; ceveCutDetail: Array<CeveCutDetail> } = {
      ceveCut: dataToSave,
      ceveCutDetail: details,
    };

    this.inventoryCutService
      .editCeveCut(dataSend)
      .toPromise()
      .then((x) => {
        this.selectEvent();
        Swal.close();
        this.isSaving = false;
        this.globalFunctionService.messageToast(`Carátula de inventario guardada.`, 'success');
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        Swal.close();
        this.isSaving = false;
        this.globalFunctionService.messageToast(`No se pudo abrir el inventario.`, 'error');
      });
  }

  async saveCeveCut() {
    let dataToSave: CeveCut = new CeveCut();
    var userName = this.settings.getUser().Name;
    // var stringDate = this.datepipe.transform(new Date(), 'yyy,MM,dd');
    var date = new Date(this.actualDateTime);

    dataToSave.ModifiedAt = new Date(this.actualDateTime);
    dataToSave.ModifiedBy = this.userLogin.Name;
    dataToSave.CreatedAt =
      this.persistentCreatedAt != undefined
        ? this.persistentCreatedAt
        : await this.getCentralTime();
    dataToSave.CreatedBy =
      this.persistentCreatedBy != ''
        ? this.persistentCreatedBy
        : this.userLogin.Name;
    dataToSave.Org = this.userLogin.Org;
    dataToSave.UnitId = this.unitId;
    dataToSave.DispatcherReciverSign = '';
    dataToSave.ManagerSign = '';
    dataToSave.SupervisorSign = '';
    dataToSave.Status = CeveCutStatus.SAVED;
    dataToSave.CeveCutDay = this.selectedDate;
    var details = this.setCeveCutDetail(userName, date);
    //send request
    var dataSend: { ceveCut: CeveCut; ceveCutDetail: Array<CeveCutDetail> } = {
      ceveCut: dataToSave,
      ceveCutDetail: details,
    };

    this.inventoryCutService.addCeveCut(dataSend).subscribe((x) => {
      this.selectEvent();
      this.isSaving = false;
      Swal.close();
      this.globalFunctionService.messageToast(`Carátula de inventario guardada.`, 'success');
    });
  }

  setCeveCutDetail(userName: string, date: Date) {
    let detailToSave: CeveCutDetail[] = [];
    var containerId;
    var initialBalanceLine = 100;
    //SetLines:__
    for (let i = 0; i < this.containerData.length; i++) {
      if (this.containerData[i].ContainerId != 'N/F') {
        containerId = this.containerData[i].ContainerId;
        //PhysicalInventory
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.PhysicalInventory,
          ContainerId: containerId,
          Quantity: this.physicalInventory.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //Coupons
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.Coupons,
          ContainerId: containerId,
          Quantity: this.couponInventory.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //Promisses
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.PromissoryNote,
          ContainerId: containerId,
          Quantity: this.promissoryNoteInventory.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //TotalVehicles
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.TotalVehicle,
          ContainerId: containerId,
          Quantity: this.totalInventoryVehicle.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //RTM
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.RtmVehicles,
          ContainerId: containerId,
          Quantity: this.rtmVehicles.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //Route
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.RouteCharges,
          ContainerId: containerId,
          Quantity: this.routeCharges.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //StockAllocation
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.StockAllocation,
          ContainerId: containerId,
          Quantity: this.stockAllocation.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //VanLoadRejection
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.VanLoadRejection,
          ContainerId: containerId,
          Quantity: this.vanLoadRejection.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //TotalPhysicalInventory
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.TotalPhysicalInventory,
          ContainerId: containerId,
          Quantity: this.totalPhysicalInventory.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //LogisticsBalance
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.LogisticsBalance,
          ContainerId: containerId,
          Quantity: this.logisticsBalance.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //ChargeToCollaborator
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.ChargeToCollaborator,
          ContainerId: containerId,
          Quantity: this.chargedToCollaborator[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //ValeChofer
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.valeChofer,
          ContainerId: containerId,
          Quantity: this.valeChofer[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //ValeColabs
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.valeColabs,
          ContainerId: containerId,
          Quantity: this.valeColabs[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //Compulsas
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.compulsas,
          ContainerId: containerId,
          Quantity: this.compulsas[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //paybillAS
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.paybillAS,
          ContainerId: containerId,
          Quantity: this.paybillAS[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //diffNotAttributable
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.DifferenceNotAttributable,
          ContainerId: containerId,
          Quantity: this.diffNotAttributable.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //supposedInventory
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.SupposedInventory,
          ContainerId: containerId,
          Quantity: this.supposedInventory[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //diffAttributable
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.DifferenceAttributable,
          ContainerId: containerId,
          Quantity: this.diffAttributable.controls[i].value,
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //SecurityRecovery
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.SecurityRecovery,
          ContainerId: containerId,
          Quantity: this.securityRecovery[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //Losses
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.Losses,
          ContainerId: containerId,
          Quantity: this.losses[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //AsDriverVehicles
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.asDriverVehicles,
          ContainerId: containerId,
          Quantity: this.asDriverVehicles[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //AsClient
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.asClients,
          ContainerId: containerId,
          Quantity: this.asClients[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //Transit
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.Transit,
          ContainerId: containerId,
          Quantity: this.transit[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //TransitLeader
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.TransitLeader,
          ContainerId: containerId,
          Quantity: this.transitLeader[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //PreviousInventory
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.PreviousInventory,
          ContainerId: containerId,
          Quantity: this.previousInventory[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //InputMovements
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.InputMovements,
          ContainerId: containerId,
          Quantity: this.inputMovements[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //OutputMovements
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.OutputMovements,
          ContainerId: containerId,
          Quantity: this.outputMovements[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //InvAvailableCalculated
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.InvAvailableCalculated,
          ContainerId: containerId,
          Quantity: this.invAvailableCalculated[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //ClosingInventory
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.ClosingInventory,
          ContainerId: containerId,
          Quantity: this.closingInventory[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //CurrentComodato
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.CurrentComodato,
          ContainerId: containerId,
          Quantity: this.currentComodato[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //ExpiredComodato
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.ExpiredComodato,
          ContainerId: containerId,
          Quantity: this.expiredComodato[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
        //ExpiredChargedComodato
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.ExpiredChargedComodato,
          ContainerId: containerId,
          Quantity: this.expiredChargedComodato[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });

        //Miscellaneous
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.Miscellaneous,
          ContainerId: containerId,
          Quantity: this.miscellaneous[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });

        //AdjustCeve
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.AdjustCeve,
          ContainerId: containerId,
          Quantity: this.adjustCeve[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });

        //TotalDifferences
        detailToSave.push({
          CeveCutId: this.ceveCutId,
          Line: CeveCutLineType.TotalDifferences,
          ContainerId: containerId,
          Quantity: this.totalDifferences[i],
          CreatedBy: userName,
          CreatedAt: date,
          ModifiedBy: userName,
          ModifiedAt: date,
          NodeName: '',
        });
      }
    }
    return detailToSave;
  }

  async saveSigns(sign: string) {
    const confirm = await this.warningApply(
      `¿Desea firmar el corte?. Al ingresar la tercer firma el corte será publicado.`
    );
    if (!confirm) {
      Swal.close();
      this.isSaving = false;
      return;
    }
    this.showLoading();
    this.updateTime();
    this.isSaving = true;
    var signInfo = `${this.userLogin.Name}|${
      this.userLogin.Email != '' ? this.userLogin.Email : ''
    }|Fecha: ${this.actualDate}`;
    this.editCeveCut(CeveCutStatus.INCOMPLETESIGNED, [sign, signInfo]);
  }

  async publishInventory() {
    this.isSaving = true;
    this.showLoading();
    this.updateTime();
    this.inventoryCutService
      .publishCeveCut(this.ceveCutId)
      .toPromise()
      .then((x) => {
        this.addMovementForInventoryCut();
      })
      .catch((error: HttpErrorResponse) => {
        this.globalFunctionService.messageToast(`Error al publicar el inventario.`, 'error');
        console.log(error.error);
        this.isSaving = false;
      });
  }

  addMovementForInventoryCut() {
    var positiveMovements: Array<InventoryMovementDetail> = [];
    var negativeMovements: Array<InventoryMovementDetail> = [];
    var movementsList: Array<movObj> = [];
    var unitPersist = this.unitId;
    for (let index = 0; index < this.containerData.length; index++) {
      if (this.diffAttributable.controls[index].value > 0) {
        var newDetail: InventoryMovementDetail = new InventoryMovementDetail();
        newDetail.Org = this.userLogin.Org;
        newDetail.MovementId = '';
        newDetail.MovementType = 'AJUST';
        newDetail.MoveDate = this.selectedDate;
        newDetail.ConteinerId = this.containerData[index].ContainerId;
        newDetail.ConteinerType = 'EV';
        newDetail.Quantity = this.diffAttributable.controls[index].value;
        newDetail.CreatedBy = this.userLogin.Name;
        newDetail.ModifiedBy = this.userLogin.Name;
        positiveMovements.push(newDetail);
      } else if (this.diffAttributable.controls[index].value < 0) {
        var newDetail: InventoryMovementDetail = new InventoryMovementDetail();
        newDetail.Org = this.userLogin.Org;
        newDetail.MovementId = '';
        newDetail.MovementType = 'AJUST';
        newDetail.MoveDate = this.selectedDate;
        newDetail.ConteinerId = this.containerData[index].ContainerId;
        newDetail.ConteinerType = 'EV';
        newDetail.Quantity = this.diffAttributable.controls[index].value * -1;
        newDetail.CreatedBy = this.userLogin.Name;
        newDetail.ModifiedBy = this.userLogin.Name;
        negativeMovements.push(newDetail);
      }
    }

    if (positiveMovements.length > 0) {
      var posHeader = new InventoryMovement();
      posHeader.Org = this.userLogin.Org;
      posHeader.MovementId = '';
      posHeader.MoveDate = this.selectedDate;
      posHeader.OutputUnit = 0;
      posHeader.InputUnit = unitPersist;
      posHeader.MovementType = 'AJUST';
      posHeader.Applied = false;
      posHeader.Reference = this.ceveCutId;
      posHeader.MoveInfo = 'Ajuste de caratula de centro de ventas faltante';
      posHeader.CreatedBy = this.userLogin.Name;
      posHeader.ModifiedBy = this.userLogin.Name;
      posHeader.MoveInfo = 'Alta ajuste de inventario CEVE ';
      let posMov: movObj = {
        Movement: posHeader,
        Details: positiveMovements,
      };
      movementsList.push(posMov);
    }
    if (negativeMovements.length > 0) {
      var negHeader = new InventoryMovement();
      negHeader.Org = this.userLogin.Org;
      negHeader.MovementId = '';
      negHeader.MoveDate = this.selectedDate;
      negHeader.OutputUnit = unitPersist;
      negHeader.InputUnit = 0;
      negHeader.MovementType = 'AJUST';
      negHeader.Applied = false;
      negHeader.Reference = this.ceveCutId;
      negHeader.MoveInfo = 'Baja ajuste de inventario CEVE';
      negHeader.CreatedBy = this.userLogin.Name;
      negHeader.ModifiedBy = this.userLogin.Name;
      let negMov: movObj = {
        Movement: negHeader,
        Details: negativeMovements,
      };
      movementsList.push(negMov);
    }
    this.inventoryCutService
      .addMovementForInventoryCut(unitPersist, this.selectedDate, movementsList)
      .toPromise()
      .then(() => {
        this.globalFunctionService.messageToast(`Carátula de inventario guardada.`, 'success');
        this.selectEvent();
        this.isSaving = false;
      })
      .catch((error: HttpErrorResponse) => {
        this.globalFunctionService.messageToast(`No se pudo publicar el inventario.`, 'error');
        console.log(error);
        this.isSaving = false;
      });
  }

  print(): void {
    window.print();
  }

  outOfTime() {
    this.updateTime();
    if (this.status == CeveCutStatus.LAPSED) {
      Swal.fire({
        icon: 'warning',
        title: 'La carátula ha caducado.',
        text:
          'El límite para la captura de inventario fue hasta el día y hora: ' +
          this.lastEvent.EndAt.toString(),
      });
      return;
    }
  }

  async updateTime() {
    this.actualDateTime = this.datepipe.transform(
      await this.getCentralTime(),
      'yyyy-MM-ddTHH:mm:ss'
    );
  }

  showLoading() {
    Swal.fire({
      title: 'En proceso...',
      html: 'Por favor espere',
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  toggleChargeCollapsed() {
    this.chargedIsCollapsed = !this.chargedIsCollapsed;
    console.log(this.chargedIsCollapsed);
  }

  async warningApply(message: string): Promise<boolean> {
    const result = await Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`,
    });
    return result.isConfirmed;
  }
}
