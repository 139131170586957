<br />
<tabset [justified]="true">
  <tab>
    <h3 class="text-center text-thin mt-4" [innerHTML]="'offsidebar.setting.SETTINGS' | translate"></h3>


    <div class="p-2">
      <h4 class="text-muted text-center text-thin">TEMAS</h4>
      <div class="row row-flush mb-2">
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="E" />
              <span class="icon-check"></span>
              <span class="color bg-info"></span>
              <span class="split">
                <span class="color" style="background-color: #680909"></span>
                <span class="color bg-gray-dark"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="F" />
              <span class="icon-check"></span>
              <span class="color bg-green-dark"></span>
              <span class="split">
                <span class="color bg-gray-dark"></span>
                <span class="color bg-green"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="G" />
              <span class="icon-check"></span>
              <span class="color" style="background-color: #680909"></span>
              <span class="split">
                <span class="color bg-info-dark"></span>
                <span class="color bg-info-light"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="row row-flush mb-2">
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="H" />
              <span class="icon-check"></span>
              <span class="color" style="background-color: #000000"></span>
              <span class="split">
                <span class="color bg-gray-darker"></span>
                <span class="color bg-gray-dark"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="A" />
              <span class="icon-check"></span>
              <span class="color" style="background-color: #571b0c"></span>
              <span class="split">
                <span class="color" style="background-color: #6E3B2C"></span>
                <span class="color" style="background-color: #3F2B24"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="B" />
              <span class="icon-check"></span>
              <span class="color" style="background-color: #030571"></span>
              <span class="split">
                <span class="color" style="background-color: #035bc0"></span>
                <span class="color" style="background-color: #3F2B24"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="row row-flush mb-2">
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="C" />
              <span class="icon-check"></span>
              <span class="color" style="background-color: #DA0826"></span>
              <span class="split">
                <span class="color" style="background-color: #870725"></span>
                <span class="color" style="background-color: #F74F4F"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="D" />
              <span class="icon-check"></span>
              <span class="color" style="background-color: #560623"></span>
              <span class="split">
                <span class="color" style="background-color: #DD0F5A"></span>
                <span class="color" style="background-color: #810934"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="setting-color">
            <label>
              <input type="radio" name="setting-theme" [(ngModel)]="currentTheme" (ngModelChange)="setTheme()"
                value="I" />
              <span class="icon-check"></span>
              <span class="color" style="background-color: #0B601C"></span>
              <span class="split">
                <span class="color" style="background-color: #088f23"></span>
                <span class="color" style="background-color: #d5f1a1"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="row row-flush mb-2">
        <div class="col mb-2">
        </div>
        <div class="col mb-2">
        </div>
        <div class="col mb-2">
        </div>
      </div>
    </div>
    <div class="p-2" hidden>
      <h4 class="text-muted text-thin">Language</h4>
      <select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
        <option [value]="lang.code" *ngFor="let lang of getLangs()">{{lang.text}}</option>
      </select>
    </div>
  </tab>
</tabset>

<div *ngIf="hasWidgetAuth" class="text-center mt-4">
  <div class="d-flex justify-content-center">
  </div>
  <h4 class="text-muted text-center text-thin">CONFIGURACIÓN DE TABLERO</h4>
</div>

<div *ngIf="hasWidgetAuth" class="row">
  <div class="col-xl-10">
    <div class="card">
        <div class="row">
          <div class="col-lg-2"></div>
        <div class="col-lg-4 br">
          <label>Activar</label>
          <label class="switch m-0">
            <input type="checkbox" checked="checked" [(ngModel)]="widgetStatus" (click)="toggleWidgets()" class="active-widget" />
            <span></span>
          </label>
        </div>
        <div class="col-lg-4">
          <label>Intervalo</label><br>
          <select (ngModelChange)="setWidgetInterval($event)" [(ngModel)]="interval" class="form-select" aria-label="Default">
            <option value="0">Desactivado</option>
            <option selected value="300000">5 min</option>
            <option value="600000">10 min</option>
            <option value="900000">15 min</option>
            <option value="1200000">20 min</option>
            <option value="1800000">30 min</option>
          </select>
        </div>
        </div><br>
      </div>
  </div>
</div>

<!-- Cambio de Sistema -->
<div *ngIf="MultiSystem" class="text-center mt-4">
  <h4 class="text-muted text-center text-thin">SISTEMA</h4>
  <div class="row" style="justify-content: center;">
    <select class="form-control w-60" [(ngModel)]="Org" (ngModelChange)='OrgChange($event)' name="OrgData" [hidden]="!MultiSystem">
      <option [value]="origin.Org" *ngFor="let origin of Organization">{{origin.OrgName}}</option>
    </select>
  </div>
</div>
