import { BaseModel } from './BaseModel.Model';

export class Ceve extends BaseModel {
  UnitId: number;
  Org: number;
  CeveCode: string;
  CeveName: string;
  LabelLimit: number;
  OrgName: string;
  OrgUnit: number;
  CountryName: string;
  CountryUnit: number;
  RegionName: string;
  RegionUnit: number;
  ManagementName: string;
  ManagementUnit: number;

  DivisionName: string;
  DivisionUnit: number;

  FactoryName: string;
  FactoryUnit: number;

  DispatchMngrId: number;
  DispatchSubId: number;
  SelfServiceMngrId: number;
  SelfServiceDivId: number;

  CeveActive: boolean;
  UnitOrg: number;
  ManagerSystem: string;
  CollectionCenter: number;
  IncidentRTM: boolean;
  IncidentIV: boolean;
  

  constructor() {
    super();
    this.UnitId = 0;
    this.CeveCode = "";
    this.CeveName = "";

    this.OrgName = "";
    this.OrgUnit = 0;
    this.CountryName = "";
    this.CountryUnit = 0;
    this.RegionName = "";
    this.RegionUnit = 0;
    this.ManagementName = "";
    this.ManagementUnit = 0;
    this.DivisionName = "";
    this.DivisionUnit = 0;
    this.LabelLimit = 0;
    this.FactoryName = "";
    this.FactoryUnit = 0;
    this.DispatchMngrId = 0;
    this.DispatchSubId = 0;
    this.SelfServiceMngrId = 0;
    this.SelfServiceDivId = 0;
    this.CeveActive = false;
    this.UnitOrg = 0;
    this.CollectionCenter = 0;
    this.IncidentRTM = false;
    this.IncidentIV = false;
    }
}

export class CeveNodeGroup extends Ceve {
  isLeader: boolean;
  constructor() {
    super();
    this.isLeader = false;
  }
}
