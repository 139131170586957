import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service'
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { Unit } from '../../common/models/Unit.Model';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-rep-label',
  templateUrl: './rep-label.component.html',
  styleUrls: ['./rep-label.component.scss']
})
export class RepLabelComponent implements OnInit {

  constructor(private reportService: ReportsService,
    private globalFunctionService: GlobalFunctionsService) { }

  downloading: boolean = false;
  currentDate: Date;
  minDate: string;
  maxDate: string;
  today:string;
  unitList: Unit[];
  unitSelectedId: number = 0;
  working: boolean = false;
  datetype: number = 0;

  ngOnInit(): void {
    this.globalFunctionService.canOperate();
    let org = 1;
    let userId = 0;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null && user != undefined) {
      userId = user.Id
      org = user.Org;
    }

    this.minDate = this.globalFunctionService.getStringDate(new Date, true, 0);
    this.maxDate = this.globalFunctionService.getStringDate(new Date, true, 0);
    this.today = this.globalFunctionService.getStringDate(new Date, true, 0);
    
    this.reportService.getUserNodes(org, userId, "0").subscribe((data: any[]) => {
      this.unitList = data.filter(u => u.UnitLevel == "Centro de Ventas");
    });
  }

  selectedUnitChange(unit: Unit) {
    this.unitSelectedId = unit.UnitId;
  }

  unitCleared(): void {
    this.unitSelectedId = 0;
  }

  download(): void {

    if (!this.validateFilters()) { return; }

    this.downloading = true;
    this.reportService.downloadLabelExcelRep(this.unitSelectedId, this.datetype, this.minDate, this.maxDate).subscribe(resp => {
      console.log(resp);
      let blob = new Blob([resp], { type: resp.type });
      const anchor = document.createElement('a');
      anchor.download = `Reporte etiquetas.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
    }, error => {
      this.downloading = false;
      this.globalFunctionService.messageToast(`Ocurrio un error al generar el archivo, por favor vuelva a intentar.`, 'warning');
    }
      , () => {
        this.downloading = false;
      });
  }

  validateFilters(): boolean {
    console.log('inicial', this.minDate);
    console.log('final',this.maxDate);
    if (this.minDate == "") {
      this.globalFunctionService.messageToast(`Se debe especificar la fecha inicial.`, 'warning');
      return false;
    }
    if (this.maxDate == "") {
      this.globalFunctionService.messageToast(`Se debe especificar la fecha final.`, 'warning');
      return false;
    }

    if (new Date(this.maxDate) < new Date(this.minDate)) {
      this.globalFunctionService.messageToast(`La fecha inicial no puede ser mayor a la fecha final.`, 'warning');
      return false;
    }

    return true;
  }

}
