import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportIncidence } from '../../common/models/ReportIncidence.Model';
import { ReportsService } from '../reports.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { Unit } from '../../common/models/Unit.Model';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { Calendar } from '../../common/models/Calendar.Model';
import { CalendarService } from '../../configuration/calendar/calendar.service';
import { Router } from '@angular/router';
import { AdjustService } from '../../inventory/adjust/adjust.service';

@Component({
  selector: 'app-rep-incidence',
  templateUrl: './rep-incidence.component.html',
  styleUrls: ['./rep-incidence.component.scss'],
})
export class RepIncidenceComponent implements OnInit {
  constructor(
    private reportService: ReportsService,
    private settingsServ: SettingsService,
    private calendarService : CalendarService,
    private router: Router,
    private adjustService:AdjustService,
    private globalFunctionService: GlobalFunctionsService
  ) {}

  user: UserLogin = null;
  OriginNode: Unit = new Unit();
  DestinyNode: Unit = new Unit();
  nodesList: Unit[] = [];
  nodesUser: Unit[] = [];
  organizacionList: Unit[];
  downloading: boolean = false;
  nodeSelected: boolean = false;
  working: boolean = false;
  originSelected: number = 0;
  organizationSelected: string = '0';
  initialDate: string = '';
  finalDate: string = '';
  status: string = '';
  actualDate: string = '';
  inputKeyword: string = 'UnitDescription';
  reportIncidence: ReportIncidence[];
  datepipe: DatePipe = new DatePipe('en-US');
  region: Unit[];
  regionSelected: string = '0';
  statusList: string[] = [
    'Abierto',
    'Rechazado',
    'Cerrado',
    'Escalado',
    'Propuesta Segundo Nivel',
    'Caducado',
  ];

  async ngOnInit() {
    this.globalFunctionService.canOperate();
    await this.calendarService.CalendarInventoryInProgress().subscribe((calendar: Calendar[] )=>{
      if (calendar.length==1)
      {
        this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en toma de inventario fisico.`, 'warning');
        this.router.navigate([`/home`]);
      }
    });

    await this.adjustService.validateAdjustActive(this.settingsServ.getUser().UnitId)
      .toPromise()
      .then(resp => {
        if (resp.AdjustCode !== '') {
          this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en compulsa.`, 'warning');
          this.router.navigate([`/home`]);
        }
      });

    this.user = this.settingsServ.getUser();
    this.actualDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    var date = new Date();
      this.initialDate = this.actualDate;
      this.finalDate = this.actualDate;
    this.getOrganizacion();
  }

  getOrganizacion(): void {
    this.working = true;
    let userId=0;
    let org=1;
    let user = JSON.parse(localStorage.getItem("user"));
     if (user != null && user != undefined) {
       userId=user.Id;
       org=user.Org;
     }
    this.reportService
      .getOrganizacionesByUser(org,userId)
      .toPromise()
      .then((regiones: Unit[]) => {
        this.organizacionList = regiones;
        this.organizationSelected = '0';
        if(this.organizacionList != null){
          if(this.organizacionList.length > 0){
            this.organizationSelected = this.organizacionList[0].UnitId.toString();
          }
        }
        this.getRegion();
      })
      .catch(() => {
        this.globalFunctionService.messageToast(`Ocurrió un error al obtener la organización`, 'error');
      });
  }


  getRegion() {
    this.working == true;
      this.reportService
        .getUserRegions(this.user.Org, this.user.Id, this.organizationSelected)
        .toPromise()
        .then((resp: Unit[]) => {
          console.log(resp);
          this.region = resp;
          if(this.region != null){
            if(this.region.length > 0){
              this.regionSelected = this.region[0].UnitId.toString();
            }
          }
          this.getNodes();
        })
        .catch(() => {
          this.working = false;
        });
  }

  getNodes() {
    this.nodesUser = [];
    this.working = true;
    if(this.nodesList.length <= 0){
    this.reportService
      .getCeveFactoryList()
      .toPromise()
      .then((resp) => {
        this.nodesList = resp;
      })
      .catch((err: any) => {
        console.log(err);
        this.globalFunctionService.messageToast(`Hubo un error al obtener los nodos`, 'error');
      });
    }
    else{
      this.clearDestinyNode();
      this.clearOriginNode();
    }
    this.reportService
      .getUserNodesByRegion(this.user.Org, this.user.Id, this.regionSelected)
      .toPromise()
      .then((resp: any[]) => {
        resp.map((x) => {
          var a: Unit = new Unit();
          (a.UnitId = x.UnitId),
            (a.OrgId = x.Org),
            (a.UnitDescription = x.UnitDes),
            (a.LevelType = x.UnitLevel == 'Centro de Ventas' ? 'CEVE' : 'FAB');
          this.nodesUser.push(a);
        });
      })
      .catch((err) => {
        console.log(err);
        this.globalFunctionService.messageToast(`Hubo un error al obtener la información`, 'error');
      })
      .finally(() => {
        this.working = false;
      });
  }

  getIncidencesList() {
    this.downloading = true;
    var nodeA = 0;
    var nodeB = 0;
    nodeA = this.OriginNode.UnitId == undefined ? 0 : this.OriginNode.UnitId;
    nodeB = this.DestinyNode.UnitId == undefined ? 0 : this.DestinyNode.UnitId;
    console.log(nodeA + ' - ' + nodeB);
    if (nodeA > 0 && nodeB == 0) {
      var a = this.nodesUser.find((x) => x.UnitId == nodeA);
      console.log(a);
      if (a == null) {
        this.globalFunctionService.messageToast(`No tienes asignado el nodo seleccionado`, 'error');
      }
    } else if (nodeB > 0 && nodeA == 0) {
      var b = this.nodesUser.find((x) => x.UnitId == nodeB);
      console.log(b);
      if (b == null) {
        this.globalFunctionService.messageToast(`No tienes asignado el nodo seleccionado`, 'error');
      }
    } else if (nodeA > 0 && nodeB > 0) {
      var a = this.nodesUser.find((x) => x.UnitId == nodeA);
      console.log(a);
      var b = this.nodesUser.find((x) => x.UnitId == nodeB);
      console.log(b);
      if (a == null && b == null) {
        this.globalFunctionService.messageToast(`No tienes asignados los nodos seleccionados`, 'error');
      }
    }
    let oneRegion = this.region.find(r => r.UnitId.toString() === this.regionSelected);
    this.reportService
      .getIncidenceExcel(
        nodeA,
        nodeB,
        this.initialDate,
        this.finalDate,
        this.originSelected,
        this.status == '' ? 'TODOS' : this.status == 'Propuesta Segundo Nivel' ? 'Propuesta' : this.status,
        this.regionSelected,
        this.user.Id
      )
      .toPromise()
      .then(
        (resp: any) => {
          if (resp == null) {
            this.globalFunctionService.messageToast(`No se ha encontrado información.`, 'warning');
            return;
          }
          let blob = new Blob([resp], { type: resp.type });
          const anchor = document.createElement('a');

          if (this.OriginNode.UnitDescription !== undefined) {
            anchor.download = `Incidences-${this.OriginNode.UnitDescription}-${this.actualDate}-${this.status}.xlsx`;
          } else {
            anchor.download = `Incidences-${oneRegion.UnitDescription}-${this.actualDate}-${this.status}.xlsx`;
          }
          anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
          anchor.click();
          this.downloading = false;
        },
        (err) => {
          console.log(err);
          this.globalFunctionService.messageToast(`Ocurrió un error al convertir el archivo`, 'error');
        }
      )
      .catch((err: HttpErrorResponse) => {
        this.globalFunctionService.messageToast(`Ocurrió un error al obtener el archivo`, 'error');
      })
      .finally(() => {
        this.downloading = false;
      });
  }

  changeDate(initial: boolean) {
    if (new Date(this.initialDate) > new Date(this.finalDate)) {
      this.globalFunctionService.messageToast(`La fecha inicial no puede ser mayor a la fecha final`, 'warning');
      if (initial) {
        this.finalDate = this.initialDate;
      } else {
        this.initialDate = this.finalDate;
      }
    }
    if(this.finalDate > this.actualDate){
      this.globalFunctionService.messageToast(`No se puede selecionar una fecha posterior a la actual`, 'warning');
      this.finalDate = this.actualDate;
      if(this.initialDate > this.finalDate){
        this.initialDate = this.finalDate;
      }
    }


    var differenceDate = ((new Date(this.initialDate).getTime()) - (new Date(this.finalDate).getTime())) / ( 1000 * 60 * 60 * 24);
    if(Math.abs(differenceDate) > 30){
      this.globalFunctionService.messageToast(`El rango de fechas no puede ser mayor a 30 días`, 'warning');
      if (initial) {
        this.finalDate = this.daysToAdd(this.initialDate, 30);
        if(this.finalDate > this.actualDate){
          this.finalDate = this.actualDate;
        }
      } else {
        this.initialDate = this.daysToAdd(this.finalDate, -30);
      }
    }
  }

  daysToAdd(date: string, daysAdd: number): string{
    return this.datepipe.transform(
      new Date().setDate(new Date(date).getDate() + daysAdd),
      'yyyy-MM-dd'
    );
  }

  clearOriginNode() {
    this.OriginNode = new Unit();
    if (this.DestinyNode.UnitId == undefined) {
      this.nodeSelected = false;
    } else {
      var a = this.nodesUser.find((x) => x.UnitId == this.DestinyNode.UnitId);
      if (a == null) {
        this.DestinyNode = new Unit();
        this.nodeSelected = false;
      }
    }
  }

  clearDestinyNode() {
    this.DestinyNode = new Unit();
    if (this.OriginNode.UnitId == undefined) {
      this.nodeSelected = false;
    } else {
      var a = this.nodesUser.find((x) => x.UnitId == this.OriginNode.UnitId);
      if (a == null) {
        this.OriginNode = new Unit();
        this.nodeSelected = false;
      }
    }
  }

  clearStatus() {
    this.status = '';
  }

  nodeSelection(origin: boolean) {
    this.nodeSelected = true;
  }

}
