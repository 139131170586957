import { Component, OnInit } from '@angular/core';
import { InventoryService } from '../inventory.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { Unit } from '../../common/models/Unit.Model';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { BalanceAdjustService } from './balance-adjust.service';

import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-balance-adjust',
  templateUrl: './balance-adjust.component.html',
  styleUrls: ['./balance-adjust.component.scss']
})
export class BalanceAdjustComponent implements OnInit {

  constructor(private balanceAdjustService: BalanceAdjustService,
    private inventoryService: InventoryService,
    private settings: SettingsService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    this.getAutocompleteItems();
  }

  userLogin: UserLogin;
  step: number = 0;
  selectedFile: File = null;
  initialInputName: string = '';
  outputData: any[] = [];
  isLoadingItemsOutput: boolean = false;
  selectedUnitId: number = 0;
  selectedUnit: string;
  inputArgument = 'name';
  startDate = new Date();
  endDate = new Date();


  async downloadExmpleFile() {
    this.showLoadingAlert();
    this.balanceAdjustService.getExampleExcel().subscribe((result: any) => {
      let blob = new Blob([result], { type: result.type });
      const anchor = document.createElement('a');
      anchor.download = `Formato Ajuste de Flujos.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
    },
      (err) => {
        Swal.close();
        Swal.fire('¡Cuidado!', err, 'warning');
      },
      () => {
        Swal.close();
      });
  }


  onFileSelected(data: any) {
    console.log(data);
    console.log(data[0]);
    if (data == null) {
      return;
    }
    this.selectedFile = data[0];
    this.validateFile();
  }

  async validateFile() {
    this.step = 1;
    this.balanceAdjustService.ValidateBalanceFlowExcel(this.selectedFile).subscribe(response => {
      this.step = 0;
      Swal.fire({
        title: 'Confirmación',
        text: `Se aplicaran cambios a ${response} flujos, ¿Desea aplicar el ajuste?`,
        showDenyButton: true,
        confirmButtonText: 'Si',
        denyButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.applyChange();
        }
        else {
          this.clearFile();
        }
      })
    },
      (err) => {
        this.clearFile();
        Swal.close();
        if (err.error.Message == undefined || err.error.Message == null) {
          Swal.fire('¡Cuidado!', err.error, 'warning');
        }
        else {
          Swal.fire('¡Cuidado!', err.error.Message, 'warning');
        }
      });
  }

  async applyChange() {
    this.step = 2;
    this.balanceAdjustService.applyNewFlow(this.selectedFile).subscribe(data => {
      console.log(data);
      this.clearFile();
      Swal.fire('', `Los flujos se cargaron correctamente con el folio: ${data.ReferenceNumber}`, 'success')
    }, (err) => {
      console.log(err, 'Error');
      this.clearFile();
      Swal.close();
      if (err.error.Message == undefined || err.error.Message == null) {
        Swal.fire('¡Cuidado!', err.error, 'warning');
      }
      else {
        Swal.fire('¡Cuidado!', err.error.Message, 'warning');
      }
    });
  }


  clearFile() {
    this.step = 0;
    this.selectedFile = null;
  }


  getAutocompleteItems() {
    this.isLoadingItemsOutput = true;
    this.inventoryService.getAllFactoryCeve().subscribe((ceveList: Unit[]) => {
      this.selectedUnit = ceveList.find(x => x.UnitId == this.userLogin.UnitId).UnitDescription;
      this.selectedUnitId = this.userLogin.UnitId;
      this.outputData = ceveList.map(ceve => {
        return {
          id: ceve.UnitId,
          name: `${ceve.UnitDescription}`
        }
      });
      this.isLoadingItemsOutput = false;
    }, err => {
      this.isLoadingItemsOutput = false;
    });
  }

  selectOutputEvent(item: any) {
    this.selectedUnitId = item.id;
  }

  cmbOutputChange() {
    this.selectedUnitId = 0;
    this.initialInputName = '';
  }

  async downloadFile() {
    if (!this.validateDates()) {
      return;
    }

    this.showLoadingAlert();
    this.balanceAdjustService.searchFlows(this.selectedUnitId, this.startDate, this.endDate).subscribe((result: any) => {
      let blob = new Blob([result], { type: result.type });
      const anchor2 = document.createElement('a');
      anchor2.download = `Consulta Ajuste de Flujos.xlsx`;
      anchor2.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor2.click();
    },
      (err) => {
        Swal.close();
        if (err.error.Message == undefined || err.error.Message == null) {
          Swal.fire('¡Cuidado!', err.error, 'warning');
        }
        else {
          Swal.fire('¡Cuidado!', err.error.Message, 'warning');
        }
      },
      () => {
        Swal.close();
      });
  }

  validateDates(): boolean {
    if (new Date(this.datepipe.transform(this.startDate,'yyyy-MM-dd')) > new Date(this.datepipe.transform(this.endDate,'yyyy-MM-dd'))) {
      Swal.fire('¡Cuidado!', 'La fecha inicial no puede ser mayior a la fecha final, por favor verifique', 'warning');
      return false;
    }
    return true;
  }


  showLoadingAlert() {
    Swal.fire({
      title: 'Descargando archivo',
      html: 'Por favor espere',
      didOpen: () => {
        Swal.showLoading()
      },
    });
  }

  /**
   * 
   * @param event 
   */
  uploadClean(event: any): void {
    event.target.value = '';
  }  
}
