import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service'
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { Unit } from '../../common/models/Unit.Model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rep-adjust',
  templateUrl: './rep-adjust.component.html',
  styleUrls: ['./rep-adjust.component.scss']
})
export class RepAdjustComponent implements OnInit {

  constructor(private reportService: ReportsService,
    private globalFunctionService: GlobalFunctionsService) { }

  downloading: boolean = false;
  currentDate: Date;
  minDate: string;
  maxDate: string;
  today: string;
  unitList: any[];
  unitSelectedId: number = 0;
  unitSelectedName: string = '';

  ngOnInit(): void {
    this.globalFunctionService.canOperate();
    this.minDate = this.globalFunctionService.getStringDate(new Date, true, 0);
    this.maxDate = this.globalFunctionService.getStringDate(new Date, true, 0);
    this.today = this.globalFunctionService.getStringDate(new Date, true, 0);

    let org = 1;
    let userId = 0;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null && user != undefined) {
      userId = user.Id
      org = user.Org;
    }

    this.reportService.getUserNodes(org, userId, "0").subscribe((data: any[]) => {
      this.unitList = data.filter(u => u.UnitLevel == "Centro de Ventas").concat(data.filter(u => u.UnitLevel == 'Planta'));
      this.unitList.unshift({
        OrgId: 1,
        UnitId: 0,
        ParentId: 0,
        UnitCode: '',
        LevelType: '',
        UnitDes: 'Todos',
        CreatedAt: new Date,
        CreatedBy: '',
        ModifiedAt: new Date,
        ModifiedBy: ''
      });

      this.unitSelectedId = this.unitList[0].UnitId;
      this.unitSelectedName = this.unitList[0].UnitDes;

    });
  }

  closed(){
    if (this.unitSelectedId===0){
      this.unitSelectedName = this.unitList[0].UnitDes;
    }
  }

  selectedUnitChange(unit: any): void {
    this.unitSelectedId = unit.UnitId;
  }

  unitCleared(): void {
    this.unitSelectedId = 0;
  }

  selectedFactory(fac: any): void {
    this.unitSelectedId = 0;
  }

  download(): void {

    if (!this.validateFilters()) { return; }

    this.downloading = true;
    this.reportService.downloadAdjustReport(this.unitSelectedId, this.minDate, this.maxDate).subscribe(resp => {
      console.log(resp);
      let blob = new Blob([resp], { type: resp.type });
      const anchor = document.createElement('a');
      anchor.download = `Reporte compulsa.xlsx`;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
    }, error => {
      console.log(error);
      this.downloading = false;
      this.globalFunctionService.messageToast(`Ocurrio un error al generar el archivo, por favor vuelva a intentar.`, 'warning');
    }
      , () => {
        this.downloading = false;
      });
  }

  validateFilters(): boolean {
    console.log('inicial', this.minDate);
    console.log('final', this.maxDate);
    if (this.minDate == "") {
      this.globalFunctionService.messageToast(`Se debe especificar la fecha inicial.`, 'warning');
      return false;
    }
    if (this.maxDate == "") {
      this.globalFunctionService.messageToast(`Se debe especificar la fecha final.`, 'warning');
      return false;
    }

    if (new Date(this.maxDate) < new Date(this.minDate)) {
      this.globalFunctionService.messageToast(`La fecha inicial no puede ser mayor a la fecha final.`, 'warning');
      return false;
    }

    return true;
  }

}
