import { Injectable } from '@angular/core';
import { List } from 'lodash';
import { Observable } from 'rxjs';
import { InventoryMovementDetail } from '../common/models/InventoryMovementDetail.Model';
import { Region } from '../common/models/Region.Model';
import { Unit } from '../common/models/Unit.Model';

import { InventoryMovementType } from '../common/models/InventoryMovementType.Model';
import { GenericService } from '../common/services/GenericServices';
import { Management } from '../common/models/Management.Model';
import { UserProfile } from '../common/models/UserProfile.Model';
import { Calendar } from '../common/models/Calendar.Model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private genericService: GenericService) { }

  getCeveFactoryList() {
    return this.genericService.getRequest(`unit/GetAllFactoryCeve`, {});
  }

  getEventList(org: number): Observable<List<string>> {
    return this.genericService.getRequest(`log/${org}/logEvents`, {});
  }

  getProcessList(org: number): Observable<List<string>> {
    return this.genericService.getRequest(`validationprocess/${org}/processlist`, {});
  }

  getOrganizaciones(org: number, userId: number): Observable<List<UserProfile>> {
    return this.genericService.getRequest(`userprofile/${org}/${userId}?$filter=UnitLevel eq 'Organización'`, {});
  }
  getOrganizacionesByUser(org: number, userId: number): Observable<List<Unit>> {
    return this.genericService.getRequest(`userprofile/organizationbyuser/${org}/${userId}`, {});
  }
  /*  Extrar la lista de regiones*/
  getRegionList(org: number): Observable<List<Region>> {
    return this.genericService.getRequest(`region`, {});
  }
  getRegionByParent(parentId: number) {
    return this.genericService.getRequest(`region/getByParent/${parentId}`, {});
  }

  /*  Extrar la lista de Gerencias*/
  getGerenciasList(org: number): Observable<List<Management>> {
    return this.genericService.getRequest(`management`, {});
  }

  /*  Extrar la lista de clasificaciones*/
  getClasificacionList(org: number): Observable<List<Region>> {

    return this.genericService.getRequest(`region`, {});
  }
  /*  Extrar la lista de nodos*/
  getNodoList(unitId: string, typeQuery: number): Observable<List<Unit>> {
    return this.genericService.getRequest(`report/node/${unitId}/${typeQuery}`, {});
  }

  getUserNodes(org: number, userId: number, unitIdOrg: string): Observable<List<any>> {
    return this.genericService.getRequest(`userprofile/${org}/${userId}/${unitIdOrg}`, {});
  }

  getRegionByNode(userId: number, node: number): Observable<Unit> {
    return this.genericService.getRequest(`region/getbynode/${node}/${userId}`, {});
  }

  getUserNodesByRegion(org: number, userId: number, unitIdReg: string): Observable<List<any>> {
    return this.genericService.getRequest(`userprofile/nodesbyregion/${org}/${userId}/${unitIdReg}`, {});
  }

  getUserRegions(org: number, userId: number, unitIdOrg: string): Observable<List<any>> {
    return this.genericService.getRequest(`userprofile/regions/${org}/${userId}/${unitIdOrg}`, {});
  }

  getNodoBalanceList(unitId: string): Observable<List<Unit>> {
    return this.genericService.getRequest(`report/nodeBalance/${unitId}`, {});
  }

  getIncidenceList(origin: number, destiny: number, initialDate: string, finalDate: string, status: string) {
    return `report/incidence/${origin}/${destiny}/${initialDate}/${finalDate}/${status}`
  }

  getIncidenceExcel(origin: number, destiny: number, initialDate: string,
    finalDate: string, direction: number, status: string, organization: string, userId: number): Observable<any[]> {
    let url = `report/incidence/excel/${origin}/${destiny}/${initialDate}/${finalDate}/${direction}/${status}/${organization}/${userId}`;
    return this.genericService.getExcelRequest(url, {}, 0);
  }

  getMoveInventoryExcel(unitIdA: string, unitIdB: string, minDate: string, maxDate: string): Observable<any[]> {
    let url = `report/invmovement/${unitIdA}/${unitIdB}/${minDate}/${maxDate}/IMP`;
    return this.genericService.getExcelRequest(url, {}, 0);
  }

  getSaldosReport(origen: string, destino: string): Observable<any> {
    let url = `report/balance/${origen}/${destino}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  getRepInvMovements(unitIdA: string, unitIdB: string, minDate: string, maxDate: string, tipoMov: string): Observable<List<InventoryMovementDetail>> {
    return this.genericService.getRequest(`report/invmovement/${unitIdA}/${unitIdB}/${minDate}/${maxDate}/${tipoMov}`, {});
  }
  getRepInvMovementsExcel(minDate: string, maxDate: string, organizacion: string, tipoNode: string, tipoMovimiento: string): Observable<any[]> {
    return this.genericService.getExcelRequest(`report/invmovementexcel/${minDate}/${maxDate}/${organizacion}/${tipoNode}/${tipoMovimiento}`, {});
  }

  getRepSaldosExcel(origen: string, destino: string, regorigen: string, regdestino: string): Observable<any[]> {
    return this.genericService.getExcelRequest(`report/balance/excel/${origen}/${destino}/${regorigen}/${regdestino}`, {});
  }


  getRepEquipmentIOExcel(usrreg: string, usrrec: string, colaborador: string, pedido: string, comprobante: string, orden: string, tipo: string, equipo: string, desde: string, hasta: string, estatus: string, proveedor: string): Observable<any[]> {
    let param = "id=1";
    if (usrrec != "")
      param = param + "&usrrec=" + usrrec;
    if (usrreg != "")
      param = param + "&usrreg=" + usrreg;
    if (colaborador != "")
      param = param + "&colaborador=" + colaborador;
    if (pedido != "")
      param = param + "&pedido=" + pedido;
    if (comprobante != "")
      param = param + "&comprobante=" + comprobante;
    if (orden != "")
      param = param + "&orden=" + orden;
    if (tipo != "")
      param = param + "&tipo=" + tipo;
    if (equipo != "")
      param = param + "&equipo=" + equipo;
    if (desde != "")
      param = param + "&desde=" + desde;
    if (hasta != "")
      param = param + "&hasta=" + hasta;
    if (estatus != "")
      param = param + "&status=" + estatus;
    if (proveedor != "")
      param = param + "&proveedor=" + proveedor;


    return this.genericService.getExcelRequest(`report/equipmentio/excel` + '?' + param, {});

  }

  getRepEquipmentLoan(ceve: number, inicial: string, final: string): Observable<any[]> {
    return this.genericService.getExcelRequest(`report/equipmentloan/excel/${ceve}/${inicial}/${final}`, {});
  }


  getRepCutExcel(type: number, desde: string, hasta: string, Detalle: number): Observable<any[]> {
    return this.genericService.getExcelRequest(`report/cut/excel/${type}/${desde}/${hasta}/${Detalle}`, {});
  }

  getMovType(): Observable<List<InventoryMovementType>> {
    return this.genericService.getRequest(`report/invmovementtype`, {});
  }

  getNodeByRegionLevel(region: number, levelType: string, organiationId: number = 0, userId: number = 0) {
    return this.genericService.getRequest(`report/unitByRegionLevel/${region}/${levelType}/${organiationId}/${userId}`, {});
  }


  getNodeByLevel(levelType: string, organiationId: number = 0, userId: number = 0) {
    return this.genericService.getRequest(`report/unitByLevel/${levelType}/${organiationId}/${userId}`, {});
  }


  getInventoryReport(inventoryDate: string, userId: number, orgId: number, regionId: number, clasification: string, nodeId: number) {
    return this.genericService.getExcelRequest(`report/inventory/excel/${inventoryDate}/${userId}/${orgId}/${regionId}/${clasification}/${nodeId}`, {});
  }
  getNodesFabCeve(level: string): Observable<Unit[]> {
    let url = `unit/type/${level}?$select=UnitId,UnitDescription`;
    return this.genericService.getRequest(url, {});
  }

  getRegionUnit(): Observable<Region[]> {
    let url = `region?$select=UnitId,RegionName&$orderby=RegionName`;
    return this.genericService.getRequest(url, {});
  }

  getOngoingReportExcel(originUnit: number, region: number): Observable<any[]> {
    let url = `report/ongoing/excel/${originUnit}/${region}`;
    return this.genericService.getExcelRequest(url, {});
  }

  downloadLabelExcelRep(unitId: number, dateType: number, minDate: string, maxDate: string): Observable<any> {
    return this.genericService.getExcelRequest(`labeldetail/report/${unitId}/${dateType}/${minDate}/${maxDate}`, {});
  }

  /**
 * Retieves a list with all calendar events on database
 *
 * @return {*}  {Observable<List<Calendar>>} - List of calendar events
 * @memberof CalendarService
 */
  getCalendarEvents(): Observable<List<Calendar>> {
    return this.genericService.getRequest(`Calendar`, {});
  }

  downloadAdjustReport(unitId: number, from: string, to: string): Observable<any> {
    return this.genericService.getExcelRequest(`report/adjust/${unitId}/${from}/${to}`, {});
  }

  getUserNodesReport(): Observable<any[]> {
    let url = `report/usernodes`;
    return this.genericService.getExcelRequest(url, {});
  }

  getRolesReport(mode: number): Observable<any[]> {
    let url = `report/roles/${mode}`;
    return this.genericService.getExcelRequest(url, {});
  }

  getAllUserNodes(org: number, userId: number) {
    return this.genericService.getRequest(`UserProfile/${org}/${userId}/all`, {});
  }

  containerExchangeReport(type: string, originId: number, destinationId: number, labelCode: string, labelStartDate: string, labelEndDate: string, remissionNumber: string, remStartDate: string, remEndDate: string): Observable<any[]> {
    return this.genericService.getExcelRequest(`containerexchange/report/${type}/${originId}/${destinationId}/${labelCode}/${labelStartDate}/${labelEndDate}/${remissionNumber}/${remStartDate}/${remEndDate}`, {});
  }

}
