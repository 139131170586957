import { Component, OnInit, ViewChild } from '@angular/core';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { DeleteChildRendered } from '../../common/renders/delete-child-rendered';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { CustomerBalanceService } from '../../self-service/balance/customer-balance.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { InventoryService } from '../inventory.service'
import { SettingsService } from '../../../core/settings/settings.service';

import { Inventory } from '../../common/models/Inventory.Model';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { Unit } from '../../common/models/Unit.Model';
import { Ceve } from '../../common/models/Ceve.Model';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {

  constructor(private router: Router,
    private noticeService: InventoryService, 
    private settings: SettingsService,
    private globalService: GlobalFunctionsService, 
    private customerBalanceService: CustomerBalanceService) { }

  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  minDate: string;
  maxDate: string;
  currentDate: Date;
  date: string;
  inventoryId: number;
  levelList: Unit[];
  initialData: any[] = [];
  org : number;
  inputArgument = 'name';
  outputData: any[] = [];
  isLoadingItemsOutput: boolean;
  initialInputName: number;
  initialOutputName: string;
  userLogin: UserLogin;
  level: number;
  ceveList: Ceve[];
  orgunit: number;
  unit: number;
  pageSelected: number = 50;
  selectedUnit:string;

  options: GridOptions;
  PageSizes = [50, 100, 1000];

  gridOptions = {
    //domLayout: 'autoHeight',
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  columnDefs = [
    {
      field: 'Container.ContainerId',
      hide: true,
      sort: 'asc',
    },
    {
      headerName: 'Código',
      field: 'Container.ContainerCode',
      width: 60,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true
    },
    {
      headerName: 'Equipo',
      field: 'Container.ContainerName',
      width: 100,
      cellClass: 'align-start',
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true
    },
    {
      headerName: 'Inicial',
      type: 'rightAligned',
      field: 'Initial',
      width: 60,
      cellClass: 'text-right',
      editable: false,
      cellRenderer: (params: any) => this.globalService.separatorFormatter(params.data.Initial),
      resizable: true
    },
    {
      headerName: 'Entradas',
      type: 'rightAligned',
      field: 'Input',
      width: 60,
      cellClass: 'text-right',
      editable: false,
      cellRenderer: (params: any) => this.globalService.separatorFormatter(params.data.Input),
      resizable: true
    },
    {
      headerName: 'Salidas',
      type: 'rightAligned',
      field: 'Output',
      width: 60,
      cellClass: 'text-right',
      editable: false,
      cellRenderer: (params: any) => this.globalService.separatorFormatter(params.data.Output),
      resizable: true
    },
    {
      headerName: 'Disponible',
      type: 'rightAligned',
      field: 'Available',
      width: 60,
      cellClass: 'text-right',
      editable: false,
      cellRenderer: (params: any) => this.globalService.separatorFormatter(params.data.Available),
      resizable: true
    },
    {
      headerName: '',
      width: 1,
      cellClass: 'text-right'
    }
  ];

  ngOnInit(): void {
    this.userLogin = this.settings.getUser();
    this.org = this.settings.getUser().Org;
    this.globalService.canOperate();
    this.getUserCeves();
    this.getLevelList();
    this.getOutputByArgument("");
    this.currentDate = new Date();
    this.minDate = this.globalService.getStringDate(this.currentDate, true, 0);
    this.maxDate = this.globalService.getStringDate(this.currentDate, false, 0);
    this.date = this.maxDate;
  }

  gridEmiter(GridOptions: GridOptions) {
    this.options = GridOptions;
  }

  onPageSizeChange(value: number) {
    this.pageSelected = value;
    this.updateTable();
  }

  getLevelList(): void {
    this.noticeService.getLevelList(this.org).subscribe((resp: Unit[]) => {
      this.levelList = resp;
      const orgAll: Unit = new Unit();
      if (this.levelList !== null) {
        this.levelList.unshift(orgAll);
      }
    }, err => {
      Swal.fire({
        icon: 'warning',
        text: 'Error al obtener lista de niveles, verifique su conexión a internet',
      });
    });
  }

  changeFilterLevel(event: any): void {

       this.orgunit = event;
       if (event === '0') {
         this.ceqGrid.data = Array.from(this.initialData);
       } else {
         this.getInventoryByNode(this.orgunit);

       }
     }

  getInventoryByNode(orgUnit: number) {
    this.noticeService.getInventoryByNode(orgUnit).subscribe((inventory: Inventory[]) => {
      this.ceqGrid.data = inventory;
    });

  }
  /**
   * Triggers at change input select and sets outputData empty
   *
   * @memberof RemissionComponent
   */
  cmbOutputChange(): void {
    this.inventoryId = undefined;
    this.initialInputName = undefined;
  }

  selectOutputEvent(item: any): void {
    this.inventoryId = item.id;
    //this.changeFilterLevel(item.id);
    console.log ( item.id);
    if(this.inventoryId != undefined){
    this.updateTable();
    }
  }

  /**
   * Invokes getCeveListByArgument from remissionService and creates a new list with UnitId and code-name of entity, removes the entity in use from intput
   *
   * @param {*} item
   * @memberof RemissionComponent
   */
  getOutputByArgument(item: any) {
  this.isLoadingItemsOutput = true;
    this.noticeService.getLevelListByArgument(this.org,item).subscribe((ceveList: Unit[]) => {
    this.selectedUnit = ceveList.find(x=>x.UnitId == this.userLogin.UnitId).UnitDescription;
          this.outputData = ceveList.map(ceve => {
            return {
              id: ceve.UnitId,
              name: `${ceve.UnitDescription}`
            }
          });
        this.isLoadingItemsOutput = false;
      }, err => {
        this.isLoadingItemsOutput = false;
      });
    }

    updateTable(): void{
      if (this.minDate == undefined ) {
        Swal.fire({
          icon: 'warning',
          text: 'Seleccione una fecha de inicio',
        });
        return;
      }
      if (this.maxDate == undefined ) {
        Swal.fire({
          icon: 'warning',
          text: 'Seleccione una fecha final',
        });
        return;
      }
      if (this.inventoryId == undefined) {
        Swal.fire({
          icon: 'warning',
          text: 'Seleccione un Nivel',
        });
        return;
      }
      if (new Date(this.minDate) > new Date(this.maxDate)) {
        this.maxDate = this.minDate;
        /*Swal.fire({
          icon: 'warning',
          text: 'La fecha inicial no puede ser mayor a la fecha final',
        });*/
      }

      this.ceqGrid.endPoint =
      `inventory/date/${this.inventoryId}/${this.minDate}/${this.maxDate}?$orderby=ConteinerId&$top=${this.pageSelected}`;
      //console.log(this.ceqGrid.data);
      this.ceqGrid.refreshTable();
    }

  getUserCeves(): void {
    this.customerBalanceService.getCevesByUser(this.userLogin.Id).subscribe((ceves: Ceve[]) => {
      this.ceveList = ceves;
      this.unit = this.ceveList[0].UnitId;
      this.inventoryId = this.userLogin.UnitId;
      if(this.inventoryId != undefined){
      this.updateTable();
      }
    });
  }

  goUp(event: any): void{
    let diffToday = 1;
    if(event == 2){
      this.maxDate = moment(this.maxDate).add(1, 'days').format("YYYY-MM-DD");
      diffToday =  moment(this.maxDate).diff(this.currentDate,'days');
      if(diffToday >= 0){
        this.maxDate = this.globalService.getStringDate(this.currentDate, true, 0);
      }
    }else{
      this.minDate = moment(this.minDate).add(1, 'days').format("YYYY-MM-DD");
      diffToday =  moment(this.minDate).diff(this.currentDate,'days');
      if(diffToday >= 0){
        this.maxDate = this.globalService.getStringDate(this.currentDate, true, 0);
        this.minDate = this.globalService.getStringDate(this.currentDate, true, 0);
      }
    }
    this.updateTable();
  }

  goDwn(event: any): void{
    if(event == 2){
      this.maxDate = moment(this.maxDate).add(-1, 'days').format("YYYY-MM-DD");
      let diffInitial = moment(this.maxDate).diff(this.minDate,'days');
      if(diffInitial < 0){
        this.minDate = this.maxDate;
      }
    }else{
      this.minDate = moment(this.minDate).add(-1, 'days').format("YYYY-MM-DD");
    }
    this.updateTable();
  }

}
