<div class="content-heading">
  <div class="header-container">
    <h3>Reporte de Incidencias</h3>
    <small>Reporte Web</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="row filter-container">
                <div class="col">
                  <span>Organizaci&oacute;n</span>
                  <select class="form-control" [(ngModel)]="organizationSelected"
                          name="RegionNameOri" required [disabled]="working"
                          (ngModelChange)="getRegion()">
                    <!--<option [value]="0" selected>Todos</option>-->
                    <option *ngFor="let unit of organizacionList" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
                  </select>
                </div>


                <div class="col">
                  <span>Regi&oacute;n</span>
                  <select class="form-control" [(ngModel)]="regionSelected"
                          name="RegionNameOri" required [disabled]="working"
                          (ngModelChange)="getNodes()">
                    <!--<option [value]="0" selected>Todos</option>-->
                    <option *ngFor="let unit of region" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
                  </select>
                </div>

                <div class="col-sm-1"></div>

                <div class="col">
                  Fecha inicial
                  <input
                  [disabled]="working"
                    type="date"
                    class="form-control"
                    max="{{ actualDate }}"
                    [(ngModel)]="initialDate"
                    (change)="changeDate(true)"
                  />
                </div>
                <div class="col-sm-1"></div>
                <div class="col">
                  Fecha final
                  <input
                  [disabled]="working"
                    type="date"
                    class="form-control"
                    max="{{ actualDate }}"
                    [(ngModel)]="finalDate"
                    (change)="changeDate(false)"
                  />
                </div>

              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="row filter-container">
                <div class="col-sm-4">
                  <span>Nodo origen</span>
                  <ng-autocomplete
                    [placeholder]="'Búsqueda'"
                    [disabled]="working"
                    [data]="
                      DestinyNode.UnitId == undefined ? nodesUser : nodesList
                    "
                    [searchKeyword]="inputKeyword"
                    [itemTemplate]="itemInputTemplate"
                    [notFoundTemplate]="notFoundinputTemplate"
                    [debounceTime]="600"
                    [minQueryLength]="3"
                    (selected)="nodeSelection(true)"
                    (inputCleared)="clearOriginNode()"
                    [(ngModel)]="OriginNode"
                  >
                  </ng-autocomplete>
                  <ng-template #itemInputTemplate let-item>
                    <a [innerHTML]="item.UnitDescription"></a>
                  </ng-template>
                  <ng-template #notFoundinputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                </div>

                <div class="col-sm-4">
                  <span>Nodo destino</span>
                  <ng-autocomplete
                    [placeholder]="'Búsqueda'"
                    [disabled]="working"
                    [data]="
                      OriginNode.UnitId == undefined ? nodesUser : nodesList
                    "
                    [searchKeyword]="inputKeyword"
                    [itemTemplate]="itemOutputTemplate"
                    [notFoundTemplate]="notFoundoutputTemplate"
                    [debounceTime]="600"
                    [minQueryLength]="3"
                    (selected)="nodeSelection(false)"
                    (inputCleared)="clearDestinyNode()"
                    [(ngModel)]="DestinyNode"
                  >
                  </ng-autocomplete>
                  <ng-template #itemOutputTemplate let-item>
                    <a [innerHTML]="item.UnitDescription"></a>
                  </ng-template>
                  <ng-template #notFoundoutputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                </div>
                <div class="col-sm-2">
                  <span>Estatus</span>
                  <ng-autocomplete
                    [placeholder]="'Búsqueda'"
                    [disabled]="working"
                    [data]="statusList"
                    [searchKeyword]="inputKeyword"
                    [itemTemplate]="itemStatusTemplate"
                    [notFoundTemplate]="notFoundStatusTemplate"
                    [debounceTime]="600"
                    [minQueryLength]="3"
                    (inputCleared)="clearStatus()"
                    [(ngModel)]="status"
                  >
                  </ng-autocomplete>
                  <ng-template #itemStatusTemplate let-item>
                    <a [innerHTML]="item"></a>
                  </ng-template>
                  <ng-template #notFoundStatusTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                </div>
                <div class="col-sm-2">
                  <span>Sentido</span>
                  <select
                  required
                    [disabled]="nodeSelected || working"
                    class="form-control"
                    name="clasificationSelected"
                    [(ngModel)]="originSelected"
                  >
                    <option [value]="'0'" selected>Ambas</option>
                    <option [value]="'1'">Generadas</option>
                    <option [value]="'2'">Recibidas</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <br /><br />
          <div class="row">
            <div class="col">
              <!--Buttons-->
              <button
                class="mr-1 btn btn-labeled btn-outline-success mb-2"
                [disabled]="downloading"
                (click)="getIncidencesList()"
              >
              <span class="btn-label">
                <i
                  class="fa fa-file-excel"
                  aria-hidden="true"
                  *ngIf="!downloading"></i>           
                <i class="fa fa-circle-notch fa-spin" *ngIf="downloading"></i>
              </span>
                <span>Descargar reporte</span>
              </button>
            </div>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  </div>
</div>
