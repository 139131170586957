import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planning-import',
  templateUrl: './planning-import.component.html',
  styleUrls: ['./planning-import.component.scss']
})
export class PlanningImportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
