import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { SettingsService } from '../../../core/settings/settings.service';
import { PurchaseModel } from '../../common/models/Purchase.Model';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { PurchaseService } from '../purchase.service';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { CalendarService } from '../../configuration/calendar/calendar.service';
import { Calendar } from '../../common/models/Calendar.Model';
import { AdjustService } from '../../inventory/adjust/adjust.service';
import { CurrencyModel } from '../../common/models/Currency.Model';
import { Factory } from '../../common/models/Factory.Model';
import { EquipmentIoService } from '../../equipment-io/equipment-io.service';
import { HttpErrorResponse } from '@angular/common/http';
import { noop, reject, toNumber } from 'lodash';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';

interface nodeUser {
  LevelType: string;
  Org: number;
  UnitDescription: string;
  UnitId: number;
  UserId: number;
}

enum Status {
  Programmed = 1,
  Received = 2,
  Payment = 3,
  ReceiptReview = 4,
  BillingReview = 5,
  LatePayment = 6,
  LateReceipt = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit {
  @ViewChild('purchaseForm') purchaseForm: NgForm;
  datepipe: DatePipe = new DatePipe('en-US');

  saving: boolean = false;
  canceling: boolean = false;
  searching: boolean = false;
  toAttach: boolean = true;
  isProgrammed: boolean = false;
  isInvoicing: boolean = false;
  isDeleteInvoice: boolean = false;
  isCancelled: boolean = false;
  isCapitaliazation: boolean = false;
  canceled: boolean = false;

  isEditedData: boolean = false;
  isEditedConciliated: boolean = false;
  editedData: boolean = false;
  receivePurchase: boolean = false;
  conciliatePurchase: boolean = false;
  editInvoicing: boolean = false;
  editCapitaliza: boolean = false;
  isNew: boolean = false;
  wasReceived: boolean = false;
  wasConciliated: boolean = false;

  isTotalFreightAmount: boolean = false;

  userLogin: UserLogin;
  defaultCeve: nodeUser;
  defaultFactory: nodeUser;

  activities: string[] = [];

  purchaseDetail: any[] = [];

  provData: any[] = [];
  isLoadingItemsProv: boolean = false;
  inputProvArgument: string = 'name';
  initialProvName: string = '';
  selectedProvId: number = 0;
  selectedProvName: string = '';

  outputData: any[] = [];
  isLoadingItemsOutput: boolean;
  initialOutputName: string = '';
  selectedPurchaseName: any = '';
  selectedUnitPurchase: number = 0;

  inputData: any[] = [];
  isLoadingItemsInput: boolean;
  inputArgument = 'name';
  initialInputName: string = '';
  selectedReceivesName: any = '';
  selectedUnitreceives: number = 0;

  itemsData: any[] = [];
  selectedItemDesc: string = '';
  selectedItemId: number = 0;
  itemArgument = 'itemDesc';
  currency: CurrencyModel[] = [];
  currencySelected: number = 0;
  DefaultSize: number = 5120000;

  OrderId: string;
  ProviderId: number;
  action: string;
  status: StatusDTO[] = [];
  statusSelected: number = 0;

  quantityReceived: number = 0;
  quantity: number = 0;
  quantityConciliated: number = 0;

  purchase: PurchaseModel = new PurchaseModel();

  message: string = '';

  fileCancelName: string = 'file';


  canceltext: string = '';

  x: string = '';
  //currDate = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
  currDate = new Date(); // formatDate(new Date(), 'yyyy-MM-dd', this.locale);
  currDateString: string = '';

  MaxQ: number = 0;

  constructor(
    private purchaseServ: PurchaseService,
    private settingsServ: SettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private equipmentIoService: EquipmentIoService,
    private calendarService: CalendarService,
    private adjustService: AdjustService,
    private globalFunctionService: GlobalFunctionsService
  ) { }

  async ngOnInit() {
    this.currDateString = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    console.log(this.route.snapshot.params['orderId']);

    this.userLogin = this.settingsServ.getUser();
    this.activities = this.settingsServ.getUserAccess();
    this.toAttach = this.activities.includes('ATTACH');

    this.status.push({ Id: 0, Description: 'Sin Definir' });
    this.status.push({ Id: 1, Description: 'Programado' });
    this.status.push({ Id: 2, Description: 'Recibido' });
    this.status.push({ Id: 3, Description: 'Pagado' });
    this.status.push({ Id: 4, Description: 'Revisión Recibo' });
    this.status.push({ Id: 5, Description: 'Revisión Facturación' });
    this.status.push({ Id: 6, Description: 'Pago Atrasado' });
    this.status.push({ Id: 7, Description: 'Recibo Atrasado' });
    this.status.push({ Id: 8, Description: 'Cancelado' });

    this.OrderId = this.route.snapshot.params['orderId'];

    console.log(this.OrderId.replace('%20', ''));

    if (this.OrderId == undefined) {
      Swal.fire({
        icon: 'warning',
        text: 'No se ha proporcionado el número de pedido',
      });
      return;
    }

    this.ProviderId = this.route.snapshot.params['providerId'];
    if (this.ProviderId == undefined) {
      Swal.fire({
        icon: 'warning',
        text: 'No se ha proporcionado el proveedor',
      });
      return;
    }

    this.action = this.route.snapshot.params['typeAction'];
    if (this.action == undefined) {
      Swal.fire({
        icon: 'warning',
        text: 'No se proporciono la acción a tomar',
      });
      return;
    }

    if (this.OrderId != 'NEW') {
      this.getPrucahseInfo(this.action);
    } else {
      this.OrderId = '';
      this.editedData = true;
      this.isNew = true;
      this.getPrucahseNewInfo();
      this.getProviders();
      this.getPurchaseNodes();
      this.getReceiveNodes();
      this.getItems();
      this.getCurrency();
    }
    this.initialOutputName = this.selectedPurchaseName;

    this.settingsServ
      .getSettingsByKey('UploadSize')
      .toPromise()
      .then((x) => {
        let vtmp = Number(x.Value);
        if (vtmp != undefined) this.DefaultSize = Number(x.Value);
      })
      .catch((e: HttpErrorResponse) => { });

    this.settingsServ.getSettingsByKey('MaxPurchase')
      .toPromise()
      .then(x => {
        let vtmp = Number(x.Value);
        if (vtmp != undefined)
          this.MaxQ = Number(x.Value);
      })
      .catch((e: HttpErrorResponse) => {
      });

    // this.purchaseForm.form
    //   .get('orderId')
    //   .valueChanges.subscribe((selectedValue) => {
    //     console.log('orderId: ' + selectedValue);
    //   });
  }

  getProviders() {
    this.isLoadingItemsProv = true;
    this.purchaseServ.getProviders().subscribe(
      (provList: any[]) => {
        this.selectedProvName = provList.find(
          (x) => x.providerId == this.purchase.providerId
        )?.providerName;
        this.selectedProvId = this.purchase.providerId;
        this.provData = provList.map((prov) => {
          return {
            id: prov.providerId,
            name: `${prov.providerName}`,
          };
        });
        this.isLoadingItemsProv = false;
      },
      (err) => {
        this.isLoadingItemsProv = false;
      }
    );
  }

  getPurchaseNodes() {
    this.isLoadingItemsOutput = true;
    this.purchaseServ.getFactoriesByUser(this.userLogin.Id).subscribe(
      (ceveList: Factory[]) => {
        this.selectedPurchaseName = ceveList.find(
          (x) => x.UnitId == this.purchase.unitPurchase
        )?.FactoryName;
        this.selectedUnitPurchase = this.purchase.unitPurchase;
        console.log(this.selectedUnitPurchase);
        this.outputData = ceveList.map((ceve) => {
          return {
            id: ceve.UnitId,
            name: `${ceve.FactoryName}`,
          };
        });
        this.isLoadingItemsOutput = false;
      },
      (err) => {
        this.isLoadingItemsOutput = false;
      }
    );
  }

  getReceiveNodes() {
    this.isLoadingItemsInput = true;
    this.purchaseServ.getFactoriesByUser(this.userLogin.Id).subscribe(
      (ceveList: Factory[]) => {
        this.selectedReceivesName = ceveList.find(
          (x) => x.UnitId == this.purchase.unitReceives
        )?.FactoryName;
        this.selectedUnitreceives = this.purchase.unitReceives;
        this.inputData = ceveList.map((ceve) => {
          return {
            id: ceve.UnitId,
            name: `${ceve.FactoryName}`,
          };
        });

        this.isLoadingItemsInput = false;
      },
      (err) => {
        this.isLoadingItemsInput = false;
      }
    );
  }

  getItems() {
    this.purchaseServ.getItems().subscribe(
      (data) => {
        if (this.isNew) {
          this.purchaseDetail = [
            {
              itemId: 0,
              itemDesc: '',
              quantityReceived: 0,
              quantityConciliated: 0
            },
          ];
        }
        this.selectedItemDesc = data.find(
          (x) => x.ItemId === this.purchaseDetail[0].itemId
        )?.ItemDescription;
        this.selectedItemId = this.purchaseDetail[0].itemId;
        this.itemsData = data.map((item) => {
          return {
            itemId: item.ItemId,
            itemDesc: `${item.ItemDescription}`,
          };
        });
        console.log(this.selectedItemId + ' - ' + this.selectedItemDesc);
      },
      (err) => { }
    );
  }

  filterContainer() {
    this.selectedItemDesc = this.itemsData.find(
      (x) => x.ItemId === this.selectedItemId
    ).ItemDescription;
    this.selectedItemId = this.itemsData.find(
      (x) => x.ItemDescription === this.selectedItemDesc
    ).ItemId;
  }

  getPrucahseInfo(action: string): void {
    this.purchaseServ
      .getPurchase(this.OrderId, this.ProviderId, action)
      .subscribe(
        (data: any) => {
          this.purchase = data;
          //Guardo el numero de orden original por si la orden cambia mas de una vez
          this.purchase.orderIdOld = this.purchase.orderId;
          this.purchaseDetail = data.details;

          this.quantity = data.details[0].quantity;
          this.quantityReceived = data.details[0].quantityReceived;
          this.wasReceived = data.details[0].quantityReceived > 0;
          this.wasConciliated = data.details[0].quantityConciliated > 0;

          this.purchase.equipmentAmount = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
          }).format(this.purchase.totalEquipmentAmount);
          this.purchase.equipmentInvoiced = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
          }).format(this.purchase.totalEquipmentInvoiced);
          this.purchase.freightAmount = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
          }).format(this.purchase.totalFreightAmount);
          this.purchase.freightInvoiced = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
          }).format(this.purchase.totalFreightInvoiced);

          this.purchase.deliverDate = moment(
            new Date(this.purchase.deliverDate)
          ).format('yyyy-MM-DD');
          this.purchase.statusDesc = this.status.find(
            ({ Id }) => Id === this.purchase.status
          ).Description;
          this.statusSelected = this.purchase.status;

          if (this.purchase.status === Status.Programmed) {
            this.isProgrammed = true;
          }

          if (this.purchase.orderId === '') {
            this.toAttach = false;
          }

          if (
            this.purchase.status === Status.Payment &&
            this.action === 'CAPITALIZA'
          ) {
            this.editCapitaliza = true;
          }

          if (
            this.purchase.status === Status.Payment ||
            this.purchase.status === Status.BillingReview
          ) {
            this.isDeleteInvoice = true;
          }

          if (this.purchase.status !== Status.Cancelled) {
            this.isCancelled = true;
          }

          // Si estado Revisar Recibo, Revision Facturacion, Cancelado
          if (
            this.purchase.status === Status.ReceiptReview ||
            this.purchase.status === Status.BillingReview ||
            this.purchase.status === Status.Cancelled
          ) {
            this.editedData = false;
            this.receivePurchase = false;
            this.conciliatePurchase = false;
          }
          if (action === 'RECPUR') {
            // Si estado Recibido, Pagado, Pago Atrasado
            if (this.purchase.status === Status.Received) {
              this.editedData = false;
              this.editInvoicing = false;
              this.editCapitaliza = false;
              this.receivePurchase = false;
              //can concilaite purchase only with activity: Autoriza Compra EQV
              this.conciliatePurchase = this.activities.some(x => x.toUpperCase() == 'COMAUT');

            }
            else if (
              this.purchase.status === Status.Payment ||
              this.purchase.status === Status.LatePayment ||
              this.purchase.status === Status.ReceiptReview
            ) {
              this.editedData = false;
              this.editInvoicing = false;
              this.editCapitaliza = false;
              this.receivePurchase = false;
              this.conciliatePurchase = false;
            } else if (
              this.purchase.status === Status.Programmed ||
              this.purchase.status === Status.LateReceipt
            ) {
              this.editedData = false;
              this.editInvoicing = false;
              this.editCapitaliza = false;
              this.receivePurchase = true;
              this.conciliatePurchase = false;
            }
          } else if (action === 'EDIPUR') {
            this.editedData = true;
            this.editInvoicing = false;
            this.editCapitaliza = false;
            this.receivePurchase = false;
            this.conciliatePurchase = false;
            if (
              this.activities.find((x) => x.toUpperCase() == 'COMAUT') !=
              undefined
            ) {
              this.canceled = true;
            }
          } else if (
            action === 'INVOICE' &&
            (this.purchase.status === Status.Received ||
              this.purchase.status === Status.BillingReview ||
              this.purchase.status === Status.LatePayment)
          ) {
            this.editedData = false;
            this.receivePurchase = false;
            this.conciliatePurchase = false;
            this.editCapitaliza = false;
            this.isProgrammed = true;
            this.isInvoicing = false;
            this.isCapitaliazation = false;

            if ((this.wasConciliated || this.quantity === this.quantityReceived) && this.statusSelected !== Status.BillingReview) {
              this.editInvoicing = true;
            }
          } else if (action === 'CAPITALIZA') {
            this.editedData = false;
            this.receivePurchase = false;
            this.conciliatePurchase = false;
            this.editInvoicing = false;
            this.isInvoicing = false;
            this.isCapitaliazation = true;
            this.editCapitaliza = true;
          }

          this.getProviders();
          this.getPurchaseNodes();
          this.getReceiveNodes();
          this.getItems();
        },
        (err) => {
          this.isLoadingItemsOutput = false;
          Swal.fire('', err.error.Message, 'error').then(() => {
            window.close();
          });
        }
      );
  }

  getPrucahseNewInfo(): void {
    this.purchase = new PurchaseModel();

    this.quantity = 0;
    this.purchase.status = Status.Programmed;
    this.purchase.equipmentAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(0);
    this.purchase.equipmentInvoiced = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(0);
    this.purchase.freightAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(0);
    this.purchase.freightInvoiced = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(0);

    this.purchase.statusDesc = this.status.find(
      ({ Id }) => Id === this.purchase.status
    ).Description;
    this.statusSelected = this.purchase.status;

    if (this.purchase.status === Status.Programmed) {
      this.isProgrammed = true;
    }

    if (this.purchase.orderId === '') {
      this.toAttach = false;
    }

    if (
      this.purchase.status === Status.Payment &&
      this.action === 'CAPITALIZA'
    ) {
      this.editCapitaliza = true;
    }

    if (
      this.purchase.status === Status.Payment ||
      this.purchase.status === Status.BillingReview
    ) {
      this.isDeleteInvoice = true;
    }

    if (this.purchase.status !== Status.Cancelled) {
      this.isCancelled = true;
    }

    // Si estado Revisar Recibo, Revision Facturacion, Cancelado
    if (
      this.purchase.status === Status.ReceiptReview ||
      this.purchase.status === Status.BillingReview ||
      this.purchase.status === Status.Cancelled
    ) {
      this.editedData = false;
      this.receivePurchase = false;
      this.conciliatePurchase = false;
    }
    this.editedData = true;
    this.editInvoicing = false;
    this.editCapitaliza = false;
    this.receivePurchase = false;
    this.conciliatePurchase = false;

    this.getProviders();
    this.getPurchaseNodes();
    this.getReceiveNodes();
    this.getItems();
  }

  async savePurchase(closed: boolean = false): Promise<void> {
    this.saving = true;
    let valid = await this.onInventoryCutover();
    if (!valid) {
      this.saving = false;
      return;
    }

    let exceded = this.purchaseDetail.find(x => Number(x.quantity) > this.MaxQ);
    if (exceded != undefined && exceded != null) {
      Swal.fire({
        icon: 'warning',
        text: `La cantidad programada del articulo ${exceded.itemDesc} supera los limites establecidos (${this.MaxQ}),  no es posible continuar con la acción`
      });
      this.saving = false;
      return;
    }

    let receivedExceded = this.purchaseDetail.find(x => Number(x.quantityReceived) > this.MaxQ);
    if (receivedExceded != undefined && receivedExceded != null) {
      Swal.fire({
        icon: 'warning',
        text: `La cantidad recibida del articulo ${receivedExceded.itemDesc} supera los limites establecidos (${this.MaxQ}),  no es posible continuar con la acción`
      });
      this.saving = false;
      return;
    }

    let conicliatedExceded = this.purchaseDetail.find(x => Number(x.quantityConciliated) > this.MaxQ);
    if (conicliatedExceded != undefined && conicliatedExceded != null) {
      Swal.fire({
        icon: 'warning',
        text: `La cantidad recibida del articulo ${conicliatedExceded.itemDesc} supera los limites establecidos (${this.MaxQ}),  no es posible continuar con la acción`
      });
      this.saving = false;
      return;
    }

    if (!this.isEditedData && this.action === 'EDIPUR') {
      this.saving = false;
      return;
    }

    if (this.purchase.equipmentAmount !== '$0.00') {
      this.purchase.totalEquipmentAmount = Number.parseFloat(
        this.purchase.equipmentAmount
          .replace('$', '')
          .replace(',', '')
          .replace(',', '')
          .replace(',', '')
      );
    } else {
      this.purchase.totalEquipmentAmount = Number.parseFloat(
        this.purchase.equipmentAmount.replace('$', '')
      );
    }
    if (this.purchase.equipmentInvoiced !== '$0.00') {
      this.purchase.totalEquipmentInvoiced = Number.parseFloat(
        this.purchase.equipmentInvoiced
          .replace('$', '')
          .replace(',', '')
          .replace(',', '')
          .replace(',', '')
      );
    } else {
      this.purchase.totalEquipmentInvoiced = Number.parseFloat(
        this.purchase.equipmentInvoiced.replace('$', '')
      );
    }
    if (this.purchase.freightAmount !== '$0.00') {
      this.purchase.totalFreightAmount = Number.parseFloat(
        this.purchase.freightAmount
          .replace('$', '')
          .replace(',', '')
          .replace(',', '')
          .replace(',', '')
      );
    } else {
      this.purchase.totalFreightAmount = Number.parseFloat(
        this.purchase.freightAmount.replace('$', '')
      );
    }
    if (this.purchase.freightInvoiced !== '$0.00') {
      this.purchase.totalFreightInvoiced = Number.parseFloat(
        this.purchase.freightInvoiced
          .replace('$', '')
          .replace(',', '')
          .replace(',', '')
          .replace(',', '')
      );
    } else {
      this.purchase.totalFreightInvoiced = Number.parseFloat(
        this.purchase.freightInvoiced.replace('$', '')
      );
    }

    if (this.action === 'EDIPUR') {
      if (this.purchase.status == Status.BillingReview) {
        //flujo revision facturacion con los totales programados y facturados iguales
        if (await this.validateINVOICE().catch(() => { })) {
          await this.saveDataINVOICE().catch(() => { });
        }
      }
      else if (this.purchase.status == Status.ReceiptReview || this.isEditedConciliated) {
        //flujo de recuperacion con cantidad programada y cantidad recibida igual o captura de cantidad conciliada
        // if (this.quantity !== this.quantityReceived) {
        //   this.globalFunctionService.messageToast('La cantidad programada no puede ser diferente a la cantidad recibida.', 'warning');
        //   this.saving = false;
        //   return;
        // }
        if (this.quantityConciliated === 0) {
          this.globalFunctionService.messageToast('La cantidad conciliada no puede ser Cero.', 'warning');
          this.saving = false;
          return;
        }

        await this.saveDataRECPUR().catch(() => { });
      }
      else {
        //flujo edicion de compras , nuevo o existente
        let newStatus = '';
        if (this.purchase.status !== Status.LatePayment && this.purchase.status !== Status.Programmed) {
          if (new Date(this.purchase.deliverDate) >= new Date(this.datepipe.transform(this.currDate, 'yyy-MM-dd'))) {
            if (this.purchase.status != Status.Received) {
              newStatus = "El estatus se actualizará a 'Programado'.";
            }
            else {
              //se queda como recibido
            }
          } else {
            if (this.purchase.status != Status.Received) {
              newStatus = "El estatus se actualizará a 'Recibo Atrasado'.";
            }
            else {
              newStatus = "El estatus se actualizará a 'Pago Atrasado'.";
            }
          }
        }
        this.message = `Los campos: Costo Total Equipo Facturado, Costo Total Flete Facturado, Factura, Orden de compra de equipo, No. De receipt de equipo, Lote / Serie y Capitalización serán borrados. Los Usuarios deberán capturarlos nuevamente. ${newStatus} ¿Desea continuar?`;

        if (closed && !this.isNew) {
          const confirm = await this.warningApply(this.message);
          if (!confirm) {
            this.saving = false;
            return;
          }
        }

        if (await this.validateEDITPUR().catch(() => { })) {
          await this.saveDataEDITPUR().catch(() => { });
        }
      }
    }
    if (this.action === 'RECPUR') {
      let valid = this.validateRECPUR();
      if (!valid) {
        return;
      }
      await this.saveDataRECPUR().catch(() => { });
    }
    if (this.action === 'INVOICE') {
      if (await this.validateINVOICE().catch(() => { })) {
        await this.saveDataINVOICE().catch(() => { });
      }
    }
    if (this.action === 'CAPITALIZA' && this.editCapitaliza) {
      if (await this.validateCapitaliza().catch(() => { })) {
        await this.saveDataCAPITA().catch(() => { });
      }
    }
    this.saving = false;
    return;
  }

  async validateEDITPUR(): Promise<boolean> {
    return await new Promise(async (yes, no) => {
      if (this.purchase.orderId === '') {
        this.globalFunctionService.messageToast('Debe capturar un pedido.', 'warning');
        return no(false);
      }
      if (this.selectedProvId === 0) {
        this.globalFunctionService.messageToast('Debe seleccionar un proveedor.', 'warning');
        return no(false);
      }
      if (this.selectedUnitPurchase === 0) {
        this.globalFunctionService.messageToast('Debe seleccionar un nodo compra.', 'warning');
        return no(false);
      }
      if (this.selectedUnitreceives === 0) {
        this.globalFunctionService.messageToast('Debe seleccionar un nodo recibe.', 'warning');
        return no(false);
      }
      if (this.selectedItemId === 0) {
        this.globalFunctionService.messageToast('Debe seleccionar un Articulo.', 'warning');
        return no(false);
      }
      if (this.purchase.totalEquipmentAmount === 0) {
        this.globalFunctionService.messageToast('Debe capturar el Costo Total Equipo Programado.', 'warning');
        return no(false);
      }
      if (this.purchase.capexYear === '') {
        this.globalFunctionService.messageToast('El año de capex no puede ser vacío.', 'warning');
        return no(false);
      }
      if (this.purchase.totalFreightAmount === 0 && this.isTotalFreightAmount) {
        let resp = await this.warningApply('El Costo Total Flete Programado esta en cero, ¿Desea continuar?');
        if (resp) {
          return yes(resp);
        }
        else {
          return no(resp);
        }
      }
      if (this.isNew && this.currencySelected == 0) {
        this.globalFunctionService.messageToast('Debe seleccionar una moneda.', 'warning');
        return no(false);
      }

      if (this.isEditedConciliated && !this.isNew) {
        if (!this.quantityConciliated || this.quantityConciliated === 0 || this.quantityConciliated == undefined) {
          this.globalFunctionService.messageToast('La cantidad conciliada no puede ser Cero.', 'warning');
          return no(false);
        }
      }
      return yes(true);
    });
  }

  validateRECPUR(): boolean {
    if (!this.quantityReceived || this.quantityReceived === 0 || this.quantityReceived == undefined) {
      this.globalFunctionService.messageToast('La cantidad recibida no puede ser Cero.', 'warning');
      return false;
    }
    return true;
  }

  async validateINVOICE(): Promise<boolean> {
    return await new Promise((yes, no) => {
      if (this.purchase.totalEquipmentInvoiced === 0) {
        this.globalFunctionService.messageToast('El Costo Total Equipo Facturado no puede ser Cero.', 'warning');
        return no(false);
      }
      if (
        this.purchase.totalFreightInvoiced === 0 &&
        this.purchase.totalFreightAmount !== 0
      ) {
        this.globalFunctionService.messageToast('El Costo Total Flete Facturado no puede ser Cero.', 'warning');
        return no(false);
      }
      if (this.purchase.invoice === '') {
        this.globalFunctionService.messageToast('La Factura no puede ser vacio.', 'warning');
        return no(false);
      }
      if (this.purchase.purchaseOrder === '') {
        this.globalFunctionService.messageToast('La Orden de Compra no puede ser vacio.', 'warning');
        return no(false);
      }
      if (this.purchase.receiptNumber === '') {
        this.globalFunctionService.messageToast('El No. De receipt de equipo no puede ser vacio.', 'warning');
        return no(false);
      }

      if (this.purchase.equipmentUuid === '') {
        this.globalFunctionService.messageToast('El Folio UUID de equipo no puede ser vacio.', 'warning');
        return no(false);
      }
      // if (!this.toAttach) {
      //   this.globalFunctionService.messageToast('Necesita la actividad para subir archivos adjuntos', 'warning');
      //   return no(false);
      // }
      // if (this.purchase.freightPurchaseOrder === '') {
      //   this.globalFunctionService.messageToast('La Orden de compra de flete no puede ser vacio.', 'warning');
      //   return no(false);
      // }
      // if (this.purchase.freightReceiptNumber === '') {
      //   this.globalFunctionService.messageToast('El No. De receipt de flete no puede ser vacio.', 'warning');
      //   return no(false);
      // }

      return yes(true);
    });
  }

  async validateCapitaliza(): Promise<boolean> {
    return new Promise((yes, no) => {
      if (this.purchase.capitalization === undefined || this.purchase.capitalization === ''
      ) {
        this.globalFunctionService.messageToast('Capitalización no puede ser vacio.', 'warning');
        return no(false);
      }
      else {
        return yes(true);
      }
    });
  }

  async saveDataEDITPUR(): Promise<void> {
    return new Promise((yes, no) => {

      this.message = 'Datos guardados correctamente.';

      if (this.purchase.status !== Status.LatePayment && this.purchase.status !== Status.Programmed) {
        if (new Date(this.purchase.deliverDate) >= new Date(this.datepipe.transform(this.currDate, 'yyy-MM-dd'))) {
          if (this.purchase.status != Status.Received) {
            this.purchase.status = Status.Programmed;
          }
          else {
            //se queda como Recibido
          }
        } else {
          if (this.purchase.status != Status.Received) {
            this.purchase.status = Status.LateReceipt;
          }
          else {
            this.purchase.status = Status.LatePayment;
          }
        }
      }

      console.log(this.purchase.totalEquipmentAmount);
      console.log(this.purchase.totalEquipmentInvoiced);

      this.purchase.containerId = this.selectedItemId;
      this.purchase.quantity = this.quantity;
      this.purchase.unitPurchase = this.selectedUnitPurchase;
      this.purchase.unitPurchaseDesc = this.selectedPurchaseName.name;
      this.purchase.unitReceives = this.selectedUnitreceives;
      this.purchase.unitReceivesDesc = this.selectedReceivesName.name;
      this.purchase.executeYear = Number(this.purchase.capexYear);
      this.purchase.currencyId = this.currencySelected;

      this.saving = true;
      this.purchaseServ.savePurchaseEdit(this.purchase).subscribe(
        async () => {
          await Swal.fire({
            icon: 'success',
            text: `${this.message}`,
          })
            .then(() => {
              this.saving = false;
              this.backClick();
              return;
            });
        },
        async (err) => {
          console.error(err.error);
          await Swal.fire({
            icon: 'warning',
            text: err.error,
          });
          this.saving = false;
          return;
        }
      );

    });
  }

  async saveDataRECPUR(): Promise<void> {
    return await new Promise(async (reject, resolve) => {
      let originalStatus = this.purchase.status;

      this.saving = true;
      // Si las cantidades son diferentes cambia el status y guarda la cantidad, en caso contrario genera la compra
      if (this.quantity !== this.quantityReceived && this.quantityConciliated === 0) {
        this.purchase.status = Status.ReceiptReview;
        // const confirm = await this.warningApply(`Se notificará al administrador para validar valores diferentes, de ser aceptados los cambios se dará ingreso del equipo a su inventario, de lo contrario será informado`);
        // if (!confirm) {
        //   this.purchase.status = originalStatus;
        //   return reject();
        // }

        this.purchaseServ
          .validateAttachment(this.purchase)
          .subscribe(async (data) => {
            if (data === null) {
              await Swal.fire({
                icon: 'warning',
                text: `Necesario adjuntar evidencia de la recepción, para proceder a guardar el registro. Utilice la opción "Documentos adjuntos"`,
              });
              this.purchase.status = originalStatus;
              this.saving = false;
              return reject();
            } else {
              this.purchaseServ
                .savePurchaseReceiveDiff(
                  this.purchase.orderId,
                  Status.ReceiptReview,
                  'Cantidad Recibida es diferente a la programada',
                  this.purchase
                )
                .subscribe(
                  async () => {
                    await Swal.fire({
                      icon: 'success',
                      text: 'Cantidad recibida ha generado entrada a su inventario. El estatus actual de la compra es Revision Recibo, favor de capturar los datos de facturación.',
                    });
                    this.saving = false;
                    this.backClick();
                    return resolve();
                  },
                  async (err) => {
                    console.error(err.error);
                    await Swal.fire({
                      icon: 'warning',
                      text: err.error,
                    });
                    this.purchase.status = originalStatus;
                    this.saving = false;
                    return;
                  }
                );
            }
          });
      } else {
        this.purchase.status = Status.Received;
        //si la cantidad coinciliada es cero es porque la compra se recibe completa
        if (this.quantityConciliated === 0) {
          this.purchaseServ
            .validateAttachment(this.purchase)
            .subscribe(async (data) => {
              if (data === null) {
                await Swal.fire({
                  icon: 'warning',
                  text: `Es necesario adjuntar evidencia de la recepción, para proceder a guardar el registro. Utilice la opción "Documentos adjuntos"`,
                });
                this.purchase.status = originalStatus;
                this.saving = false;
                return reject();
              } else {
                this.purchaseServ.savePurchaseRecive(this.purchase).subscribe(
                  async () => {
                    await Swal.fire({
                      icon: 'success',
                      text: 'Cantidades recibidas han generado entrada a su inventario. El estatus actual de la compra es Recibido, favor de capturar los datos de facturación',
                    });
                    this.saving = false;
                    this.backClick();
                    return resolve();
                  },
                  async (err) => {
                    console.error(err.error);
                    await Swal.fire({
                      icon: 'warning',
                      text: err.error,
                    });
                    this.purchase.status = originalStatus;
                    this.saving = false;
                    return reject();
                  }
                );
              }
            });
        }
        else {//si la cantidad conciliada es mayor a cero es porque la compra estaba en estatus revision recibo y solo se debe generar la conciliacion
          await this.purchaseServ.conciliatePurchase(this.purchase)
            .toPromise()
            .then(resp => {
              Swal.fire({
                icon: 'success',
                text: 'Datos guardados y conciliados correctamente.',
              }).then(() => {
                this.saving = false;
                this.backClick();
                return resolve();
              });
            })
            .catch(err => {
              console.error(err.error);
              Swal.fire({
                icon: 'warning',
                text: err.error,
              }).then(() => {
                this.purchase.status = originalStatus;
                this.saving = false;
                return reject();
              });
            });
        }

      }
    });
  }

  async saveDataINVOICE(): Promise<void> {

    // Si los totales son diferentes solo guarda el status, en caso contrario genera la facturación
    let prevStatus = this.purchase.status;
    if (
      Number(this.purchase.totalEquipmentAmount) !==
      Number(this.purchase.totalEquipmentInvoiced) ||
      Number(this.purchase.totalFreightAmount) !==
      Number(this.purchase.totalFreightInvoiced)
    ) {
      this.message =
        `Se notificará al administrador para validar los valores diferentes, de ser aceptados, la compra cambiará a estatus 'Pagado', de lo contrario será informado`;
      this.purchase.status = Status.BillingReview;
    } else {
      this.message =
        'Una vez guardados los datos no será posible editarlos sin autorización del administrador de compras de equipo, ¿Desea continuar?';
      this.purchase.status = Status.Payment;
    }

    const confirm = await this.warningApply(this.message);
    if (!confirm) {
      this.purchase.status = prevStatus;
      return;
    }

    //No sebe pedir el archivo adjunto porque el mismo archivo se sube caundo se recibe la compra 
    // if (this.purchase.status == Status.Payment && prevStatus != Status.BillingReview) {
    //   //attachment required
    //   let valid = await this.invoiceAttachment();
    //   if (!valid) {
    //     this.purchase.status = prevStatus;
    //     return;
    //   }
    // }

    this.action = 'INVOICE';
    this.saving = true;
    this.showLoading('Guardando...');
    if (
      Number(this.purchase.totalEquipmentAmount) !==
      Number(this.purchase.totalEquipmentInvoiced) ||
      Number(this.purchase.totalFreightAmount) !==
      Number(this.purchase.totalFreightInvoiced)
    ) {
      this.message = 'Datos de pago diferente al programa guardados correctamente.';
    } else {
      this.message = 'Datos de Facturación guardados correctamente.';
    }

    await this.purchaseServ
      .savePurhcaseInvoice(this.action, this.message, this.purchase)
      .toPromise()
      .then(
        async () => {
          Swal.close();
          await Swal.fire({
            icon: 'success',
            text: `${this.message}`,
          });
          this.saving = false;
          this.backClick();
        })
      .catch(async err => {
        this.purchase.status = prevStatus;
        console.error(err.error);
        await Swal.fire({
          icon: 'warning',
          text: err.error,
        });
        this.saving = false;
        return;
      });
  }

  // async invoiceAttachment(): Promise<boolean> {
  //   let isvalid: boolean = true;
  //   return await new Promise(async (resolve, reject) => {
  //     await Swal.fire({
  //       title:
  //         'Se requiere un documento adjunto para guardar los datos de factura',
  //       input: 'file',
  //       inputAttributes: {
  //         autocapitalize: 'off',
  //       },
  //       html: `<div class="row"></div>`,
  //       showCancelButton: true,
  //       confirmButtonText: 'Continuar',
  //       showLoaderOnConfirm: true,
  //       cancelButtonText: 'Volver',
  //       preConfirm: (msg) => {
  //         if (msg == '') {
  //           return;
  //         }
  //         return msg;
  //       },
  //       allowOutsideClick: false,
  //     }).then(async (result) => {
  //       console.log(result);

  //       if (
  //         result.isConfirmed &&
  //         result.value != undefined &&
  //         result.value != null
  //       ) {
  //         //Validar archivo:
  //         const validationType = result.value.name.toLowerCase().split('.');
  //         if (
  //           validationType[validationType.length - 1] != 'xls' &&
  //           validationType[validationType.length - 1] != 'xlsx' &&
  //           validationType[validationType.length - 1] != 'pdf' &&
  //           validationType[validationType.length - 1] != 'doc' &&
  //           validationType[validationType.length - 1] != 'png' &&
  //           validationType[validationType.length - 1] != 'xml' &&
  //           validationType[validationType.length - 1] != 'jpg' &&
  //           validationType[validationType.length - 1] != 'msg' &&
  //           validationType[validationType.length - 1] != 'jpeg' &&
  //           validationType[validationType.length - 1] != 'bmp' &&
  //           validationType[validationType.length - 1] != 'gif' &&
  //           validationType[validationType.length - 1] != 'docx' &&
  //           validationType[validationType.length - 1] != 'txt' &&
  //           validationType[validationType.length - 1] != 'elm' &&
  //           validationType[validationType.length - 1] != 'vnd.ms-excel'
  //         ) {
  //           isvalid = false;
  //           await Swal.fire(
  //             '¡Cuidado!',
  //             'El archivo no es formato: xls, xlsx, pdf, doc, docx, jpg, jpeg, png, msg, bmp, gif, txt, elm.',
  //             'warning'
  //           ).then(() => {
  //             return reject(false);
  //           });
  //         } else if (result.value.size > this.DefaultSize) {
  //           isvalid = false;
  //           await Swal.fire(
  //             '¡Cuidado!',
  //             'El archivo supera el tamaño permitido ' + this.DefaultSize + '.',
  //             'warning'
  //           ).then(() => {
  //             return reject(false);
  //           });
  //         }

  //         if (isvalid) {

  //           //Guardar archivo adjunto
  //           const dataForm = new FormData();
  //           dataForm.append('file-0', result.value);
  //           await this.purchaseServ
  //             .SendFile(
  //               dataForm,
  //               'ceq_compras',
  //               this.purchase.orderId
  //             )
  //             .toPromise()
  //             .then((x) => {
  //               this.saving = false;
  //               console.log('attachment saved');
  //               return resolve(true);
  //             })
  //             .catch((e: HttpErrorResponse) => {
  //               this.saving = false;
  //               console.error(e);
  //               return reject(false);
  //             });

  //         }
  //         else {
  //           return reject(false);
  //         }

  //       } else {
  //         Swal.fire({
  //           icon: 'warning',
  //           text: `Se requiere un archivo adjunto para realizar la cancelación`,
  //         }).then(() => {
  //           return reject(false);
  //         });
  //       }
  //     });

  //   });
  // }

  async saveDataCAPITA(): Promise<void> {
    console.log('deliverDate: ' + this.purchase.deliverDate);
    console.log('currDate: ' + this.currDate);

    this.message = 'Datos guardados correctamente.';

    this.saving = true;

    this.purchaseServ
      .savePurhcaseCapitaliza(this.action, this.purchase)
      .subscribe(
        async () => {
          await Swal.fire({
            icon: 'success',
            text: `${this.message}`,
          });
          this.saving = false;
          this.backClick();
          return;
        },
        async (err) => {
          console.error(err.error);
          await Swal.fire({
            icon: 'warning',
            text: err.error,
          });
          this.saving = false;
          return;
        }
      );
  }

  backClick() {
    window.close();
  }

  selectProvEvent(item: any): void {
    this.selectedProvId = item.id;
    this.selectedProvName = item.name;
    this.purchase.providerId = item.id;
    this.purchase.providerName = item.name;
    this.isEditedData = true;
  }

  cmbProvChange() {
    this.selectedProvId = 0;
    this.initialProvName = '';
  }

  selectOutputEvent(item): void {
    this.selectedUnitPurchase = item.id;
    this.selectedPurchaseName = item.name;

    this.purchase.unitPurchase = item.id;
    this.purchase.unitPurchaseDesc = item.name;

    this.isEditedData = true;
  }

  cmbOutputChange() {
    this.initialOutputName = '';
  }

  selectInputEvent(item: any): void {
    this.selectedUnitreceives = item.id;
    this.selectedReceivesName = item.name;

    this.purchase.unitReceives = item.id;
    this.purchase.unitReceivesDesc = item.name;

    this.isEditedData = true;
  }

  selectItemEvent(item: any): void {
    this.selectedItemDesc = item.ItemDescription;
    this.selectedItemId = item.itemId;
    this.isEditedData = true;
  }

  selectCurrencyEvent(): void {
    console.log(this.currencySelected);
  }

  cmbInputChange(): void {
    this.initialInputName = '';
  }

  cmbItemChange(): void {
    this.selectedItemDesc = '';
    this.selectedItemId = 0;
  }

  btnAttachement() {
    window.open(
      `/purchase/attachment/ceq_compras/${this.purchase.orderId}/Compras/CED`,
      '_blank'
    );
  }

  btnComments() {
    window.open(
      '/equipment-io/eventlog-list/' + this.purchase.orderId,
      '_blank'
    );
  }

  async btnDeleteQuantity(): Promise<void> {
    let message;
    if (
      this.purchase.status === Status.Received ||
      this.purchase.status === Status.LatePayment ||
      this.purchase.status === Status.ReceiptReview
    ) {
      message = `La cantidad recibida y la cantiad conciliada serán eliminadas y generará una salida del inventario con movimiento 'Cancelación compra'. El estatus cambiará a 'Programado' o 'Recibo Atrasado', ¿Desea continuar?`;
    } else
    // if (
    //   this.purchase.status === Status.Payment ||
    //   this.purchase.status === Status.BillingReview
    // ) 
    {
      message = `La Cantidad recibida, Costo equipo facturado, Costo flete facturado, Factura, Orden de compra, No. recibo y Referencia serán eliminadas y generará una salida del inventario con movimiento 'Cancelación compra'. El estatus cambiará a 'Programado' o 'Recibo Atrasado'. ¿Desea continuar?`;
    }

    const confirm = await this.warningApply(message);
    if (!confirm) {
      return;
    }
    this.showLoading();
    this.purchaseServ.cancelPurchase('CANREC', this.purchase).subscribe(
      async () => {
        Swal.close();
        this.saving = false;
        await Swal.fire({
          icon: 'success',
          text: `Las Cantidades fueron borradas y los datos de facturación eliminados.`,
        });
        this.saving = false;
        this.backClick();
      },
      async (err) => {
        Swal.close();
        this.saving = false;
        console.error(err.error);
        await Swal.fire({
          icon: 'warning',
          text: err.error,
        });
        return;
      }
    );
  }

  async btnDeleteInvoice(): Promise<void> {
    if (
      this.purchase.status === Status.Programmed ||
      this.purchase.status === Status.Received ||
      this.purchase.status === Status.ReceiptReview ||
      this.purchase.status === Status.LatePayment ||
      this.purchase.status === Status.LateReceipt ||
      this.purchase.status === Status.Cancelled
    ) {
      return;
    }

    const confirm = await this.warningApply(`Se borrará la información de Factura, Orden de Compra, No. de recibo y Referencia, el estatus cambiará ha ‘Recibido’ o ‘Pago atrasado’. Si la causa de la modificación es por refacturación debe escoger la opción 'Refacturar' ¿Desea continuar?`);
    if (!confirm) {
      return;
    }

    this.saving = true;
    let prevStatus = this.purchase.status;
    this.showLoading('Borrando datos de Factura...');

    if (new Date(this.purchase.deliverDate) >= new Date(this.datepipe.transform(this.currDate, 'yyy-MM-dd'))) {
      this.purchase.status = Status.Received;
    }
    else {
      this.purchase.status = Status.LatePayment;
    }


    this.purchase.invoice = '';
    this.purchase.purchaseOrder = '';
    this.purchase.receiptNumber = '';
    this.purchase.totalEquipmentInvoiced = 0;
    this.purchase.totalFreightInvoiced = 0;
    this.purchase.equipmentInvoiced = '';
    this.purchase.freightInvoiced = '';
    this.purchase.equipmentUuid = '';
    this.purchase.freightPurchaseOrder = '';
    this.purchase.freightReceiptNumber = '';

    this.message = `Se borraron datos de facturación.`;
    //this.purchaseServ.savePurchaseStatus(this.purchase.orderId, Status.Received, this.message, 'INVOICE', this.purchase).subscribe(async () => {
    this.purchaseServ
      .savePurhcaseInvoice('INVOICE', this.message, this.purchase)
      .subscribe(
        async () => {
          Swal.close();
          this.saving = false;
          await Swal.fire({
            icon: 'success',
            text: `${this.message}`,
          });
          this.saving = false;
          this.backClick();
        },
        async (err) => {
          Swal.close();
          console.error(err.error);
          await Swal.fire({
            icon: 'warning',
            text: err.error,
          });
          this.purchase.status = prevStatus;
          this.saving = false;
          return;
        }
      );
  }

  async btnRebillPurchase(): Promise<void> {
    if (
      this.purchase.status === Status.Programmed ||
      this.purchase.status === Status.Received ||
      this.purchase.status === Status.ReceiptReview ||
      this.purchase.status === Status.LatePayment ||
      this.purchase.status === Status.LateReceipt ||
      this.purchase.status === Status.Cancelled
    ) {
      return;
    }

    const confirm = await this.warningApply(`Los datos de facturación actualmente guardados se guardarán en el campo Facturación Cancelada para futuras referencias, el responsable del nodo compra deberá capturar nuevamente los datos de facturación nuevos, ¿Desea continuar?`);
    if (!confirm) {
      return;
    }

    this.saving = true;
    this.showLoading('Refacturando...');
    this.message = `se borraron datos de facturación por Refacturación.`;
    this.purchaseServ
      .rebillPurchase(this.message, 'REBILPUR', this.purchase)
      .subscribe(
        async () => {
          Swal.close();
          this.saving = false;
          await Swal.fire({
            icon: 'success',
            text: `${this.message}`,
          });
          this.saving = true;
          this.backClick();
        },
        async (err) => {
          Swal.close();
          console.error(err.error);
          await Swal.fire({
            icon: 'warning',
            text: err.error,
          });
          this.saving = false;
          return;
        }
      );
  }

  async btnCancelOrder(): Promise<void> {
    let isvalid = true;
    this.message = `Está por Cancelar la compra, ¿Desea continuar?`;
    const confirm = await this.warningApply(this.message);
    if (!confirm) {
      isvalid = false;
      return;
    } else {
      Swal.fire({
        title:
          'Se requiere un comentario y un documento adjunto para realizar la cancelación',
        input: 'file',
        inputAttributes: {
          autocapitalize: 'off',
        },
        html: `<div class="row"> <input type="text" value="${this.canceltext}" id="cancelText" name="cancelText" class="form-control"/>  </div>`,
        showCancelButton: true,
        confirmButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Volver',
        preConfirm: (msg) => {
          if (msg == '') {
            return;
          }
          return msg;
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        console.log(this.canceltext);
        const cancelCommentValue = Swal.getHtmlContainer().querySelector(
          '#cancelText'
        ) as HTMLInputElement | null;
        if (
          result.isConfirmed &&
          cancelCommentValue != null &&
          cancelCommentValue.value != '' &&
          result.value != undefined &&
          result.value != null
        ) {
          //Validar archivo:
          const validationType = result.value.name.toLowerCase().split('.');
          if (
            validationType[validationType.length - 1] != 'xls' &&
            validationType[validationType.length - 1] != 'xlsx' &&
            validationType[validationType.length - 1] != 'pdf' &&
            validationType[validationType.length - 1] != 'doc' &&
            validationType[validationType.length - 1] != 'png' &&
            validationType[validationType.length - 1] != 'xml' &&
            validationType[validationType.length - 1] != 'jpg' &&
            validationType[validationType.length - 1] != 'msg' &&
            validationType[validationType.length - 1] != 'jpeg' &&
            validationType[validationType.length - 1] != 'bmp' &&
            validationType[validationType.length - 1] != 'gif' &&
            validationType[validationType.length - 1] != 'docx' &&
            validationType[validationType.length - 1] != 'txt' &&
            validationType[validationType.length - 1] != 'elm' &&
            validationType[validationType.length - 1] != 'vnd.ms-excel'
          ) {
            isvalid = false;
            Swal.fire(
              '¡Cuidado!',
              'El archivo no es formato: xls, xlsx, pdf, doc, docx, jpg, jpeg, png, msg, bmp, gif, txt, elm.',
              'warning'
            ).then(() => {
              this.saving = false;
            });
            return;
          } else if (result.value.size > this.DefaultSize) {
            isvalid = false;
            Swal.fire(
              '¡Cuidado!',
              'El archivo supera el tamaño permitido ' + this.DefaultSize + '.',
              'warning'
            ).then(() => {
              this.saving = false;
            });
            return;
          }

          if (isvalid) {
            this.canceling = true;
            this.showLoading('Cancelando orden...');
            this.message = `Orden Cancelada.`;
            this.purchaseServ
              .cancelOrder(this.message, this.purchase)
              .subscribe(
                async () => {
                  //Guardar comentario
                  const msg = 'Estado: Cancelado - ' + cancelCommentValue.value;
                  this.equipmentIoService
                    .postEventLog(this.purchase.orderId, msg)
                    .toPromise()
                    .then((resp) => { })
                    .catch(() => { });

                  //Guardar archivo adjunto
                  const dataForm = new FormData();
                  dataForm.append('file-0', result.value);
                  this.purchaseServ
                    .SendFile(
                      dataForm,
                      'ceq_compras',
                      this.purchase.orderId
                    )
                    .toPromise()
                    .then((x) => {
                      this.saving = false;
                      Swal.fire(
                        '¡Éxito!',
                        'Se ha guardado la información: ',
                        'success'
                      );
                    })
                    .catch((e: HttpErrorResponse) => {
                      this.saving = false;
                      Swal.fire(
                        'Error!',
                        'Ha ocurrido un error: ' + e.error,
                        'error'
                      );
                    });

                  this.saving = false;
                  await Swal.fire({
                    icon: 'success',
                    text: `${this.message}`,
                  });
                  Swal.close();
                  this.canceling = false;
                  this.backClick();
                },
                async (err) => {
                  Swal.close();
                  this.saving = false;
                  console.error(err.error);
                  await Swal.fire({
                    icon: 'warning',
                    text: err.error,
                  });
                  this.canceling = false;
                  return;
                }
              );
          }
        } else {
          Swal.fire({
            icon: 'warning',
            text: `Se requiere un comentario y un archivo adjunto para realizar la cancelación`,
          }).then(() => {
            return;
          });
        }
      });
    }
  }

  onCancelDocSelect(data: any) {
    var cancelDoc = data;
    console.log(cancelDoc);
  }

  onChangeQuantityReceived(value: string) {
    this.isEditedData = true;
    this.quantityReceived = Number(value);
  }

  onChangeQuantityConciliated(value: string) {
    this.isEditedData = true;
    this.quantityConciliated = Number(value);
    this.isEditedConciliated = this.quantityConciliated > 0;
  }

  onChangeQuantity(value: string): void {
    this.isEditedData = true;
    this.quantity = Number(value);
  }

  onChangeEquipmentAmount(event: any): void {
    let value = event.currentTarget.value;
    this.isEditedData = true;
    this.purchase.totalEquipmentAmount = Number(
      value.replace('$', '').replace(/,/g, '')
    );
  }

  onChangeFreightAmount(event: any): void {
    let value = event.currentTarget.value;
    this.isEditedData = true;
    this.isTotalFreightAmount = true;
    this.purchase.totalFreightAmount = Number(
      value.replace('$', '').replace(/,/g, '')
    );
  }

  async warningApply(message: string): Promise<boolean> {
    const result = await Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`,
    });
    return result.isConfirmed;
  }

  onFocus_EquipmentAmount(): void {
    this.purchase.equipmentAmount = this.purchase.equipmentAmount.replace('$', '').replace(/,/gi, '');
  }

  onFucus_FreightAmount(): void {
    this.purchase.freightAmount = this.purchase.freightAmount.replace('$', '').replace(/,/gi, '');
  }

  onFocus_EquipmentInvoiced(): void {
    this.purchase.equipmentInvoiced = this.purchase.equipmentInvoiced.replace('$', '').replace(/,/gi, '');
  }

  onFocus_FreightAmount(): void {
    this.purchase.freightAmount = this.purchase.freightAmount.replace('$', '').replace(/,/gi, '');
  }

  onFocus_FreightInvoiced(): void {
    this.purchase.freightInvoiced = this.purchase.freightInvoiced.replace('$', '').replace(/,/gi, '');
  }

  formatCurrency_EquipmentAmount(event) {
    //remove firs decimal point
    while (this.purchase.equipmentAmount.split('.').length > 2) {
      this.purchase.equipmentAmount = this.purchase.equipmentAmount.replace('.', '');
    }
    var uy = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(toNumber(this.purchase.equipmentAmount));
    this.purchase.equipmentAmount = uy;
  }

  formatCurrency_EquipmentInvoiced(event) {
    //remove firs decimal point
    while (this.purchase.equipmentInvoiced.split('.').length > 2) {
      this.purchase.equipmentInvoiced = this.purchase.equipmentInvoiced.replace('.', '');
    }
    var uy = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(toNumber(this.purchase.equipmentInvoiced));
    this.purchase.equipmentInvoiced = uy;
  }

  formatCurrency_FreightAmount(event) {
    //remove firs decimal point
    while (this.purchase.freightAmount.split('.').length > 2) {
      this.purchase.freightAmount = this.purchase.freightAmount.replace('.', '');
    }
    var uy = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(toNumber(this.purchase.freightAmount));
    this.purchase.freightAmount = uy;
  }

  formatCurrency_FreightInvoiced(event) {
    //remove firs decimal point
    while (this.purchase.freightInvoiced.split('.').length > 2) {
      this.purchase.freightInvoiced = this.purchase.freightInvoiced.replace('.', '');
    }
    var uy = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(toNumber(this.purchase.freightInvoiced));
    this.purchase.freightInvoiced = uy;
  }

  onSecChange(isChecked: boolean): void {
    this.purchaseDetail[0].securityCertificate = isChecked;
    if (isChecked) {
      this.purchaseDetail[0].securityCertDesc = 'SI';
    } else {
      this.purchaseDetail[0].securityCertDesc = 'NO';
    }
  }

  onCtrlChange(isChecked: boolean): void {
    this.purchaseDetail[0].controlCertificate = isChecked;
    if (isChecked) {
      this.purchaseDetail[0].controlCertDesc = 'SI';
    } else {
      this.purchaseDetail[0].controlCertDesc = 'NO';
    }
  }

  onChangeDate(event: any) {
    this.isEditedData = true;
  }

  onChangeOrder(event: any) {
    this.isEditedData = true;
  }

  onChangePurchase(event: any) {
    this.isEditedData = true;
  }

  onChangeReceive(event: any) {
    this.isEditedData = true;
  }

  onKeyPress(event: any) {
    var regex = new RegExp('^[0-9.]+$');
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  async onInventoryCutover(): Promise<boolean> {
    return await new Promise(async (resolve, reject) => {
      // Se bloquea la recepción de remisiones durante la toma de inventario
      await this.calendarService
        .CalendarInventoryLock()
        .subscribe((calendar: Calendar[]) => {
          if (calendar.length == 1) {
            this.globalFunctionService.messageToast(`No es posible hacer compras, se encuentra en toma de inventario fisico.`, 'warning');
            this.router.navigate([`/home`]);
            return reject(false);
          }
        });

      await this.adjustService
        .validateAdjustActive(this.settingsServ.getUser().UnitId)
        .toPromise()
        .then((resp) => {
          if (resp.AdjustCode !== '') {
            this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en compulsa.`, 'warning');
            this.router.navigate([`/home`]);
            return reject(false);
          }
        });
      return resolve(true);
    });
  }

  getCurrency() {
    this.purchaseServ
      .getCurrencies()
      .toPromise()
      .then((x: CurrencyModel[]) => {
        this.currency = x;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  showLoading(title: string = 'Cargando...') {
    this.message = '';
    Swal.fire({
      icon: 'info',
      title: title,
      html: 'Por favor espere',
      allowOutsideClick: false,
      showCancelButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });
  }

}

export class StatusDTO {
  Id: number;
  Description: string;
}
