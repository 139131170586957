import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SettingsService } from '../../../core/settings/settings.service';
import { CapexModel } from '../../common/models/Capex.Model';
import { Unit } from '../../common/models/Unit.Model';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { PurchaseService } from '../purchase.service';

interface nodeUser {
  LevelType: string;
  Org: number;
  UnitDescription: string;
  UnitId: number;
  UserId: number;
}

@Component({
  selector: 'app-capex-detail',
  templateUrl: './capex-detail.component.html',
  styleUrls: ['./capex-detail.component.scss']
})
export class CapexDetailComponent implements OnInit {
  saving: boolean = false;
  editedData: boolean = false;

  org: number;
  userLogin: UserLogin;
  defaultCeve: nodeUser;
  defaultFactory: nodeUser;
  activities: string[] = []

  capexId: number = 0;

  outputData: any[] = [];
  isLoadingItemsOutput: boolean;
  inputArgument = 'name';
  initialOutputName: string = '';
  selectedCapexName: string = '';
  selectedCapexUnit: number = 0;

  isLoadingItemsCurr: boolean;
  initialCurrName: string = '';

  capex: CapexModel = new CapexModel();

  currencies: any[] = [];

  constructor(private purchaseServ: PurchaseService, private settingsServ: SettingsService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.org = this.settingsServ.getUser().Org;
    this.userLogin = this.settingsServ.getUser();
    this.activities = this.settingsServ.getUserAccess();

    this.capexId = this.route.snapshot.params['capexId'];
    if (this.capexId == undefined) {

      Swal.fire({
        icon: 'warning',
        text: 'No se ha proporcionado el número de capex',
      });
      return;
    }

    this.getCapexInfo();
  }

  getCapexInfo() {
    this.purchaseServ.getCapex(this.org, this.capexId).subscribe((data: any) => {
      this.capex = data;

      this.capex.original = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.capex.originalAmount);
      this.capex.reserve = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.capex.reserveAmount);
      this.capex.executed = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.capex.executedAmount);
      this.capex.available = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.capex.availableAmount);

      this.getCapexNodes();
      this.getCurrencies();
    }, err => {
      Swal.fire({
        icon: 'warning',
        text: `${JSON.stringify(err.error)}`,
      });
      return;
    });
  }

  getCapexNodes() {
    this.isLoadingItemsOutput = true;
    this.purchaseServ.getAllFactoryCeve().subscribe((ceveList: Unit[]) => {
      this.selectedCapexName = ceveList.find(x => x.UnitId == this.capex.unitId).UnitDescription;
      this.selectedCapexUnit = this.capex.unitId;
      this.outputData = ceveList.map(ceve => {
        return {
          id: ceve.UnitId,
          name: `${ceve.UnitDescription}`
        }
      });
      this.isLoadingItemsOutput = false;
    }, err => {
      this.isLoadingItemsOutput = false;
    });
  }

  getCurrencies() {
    this.isLoadingItemsCurr = true;
    this.purchaseServ.getCurrencies().subscribe((data: any[]) => {
      this.currencies = data.map(item => {
        return {
          id: item.currencyId,
          name: `${item.currencyName}`
        }
      });
      this.isLoadingItemsCurr = false;
    }, err => {
      Swal.fire({
        icon: 'warning',
        text: `${JSON.stringify(err.error)}`,
      });
      this.isLoadingItemsCurr = false;
      return;
    });
  }

  selectOutputEvent(item: any): void {
    this.selectedCapexUnit = item.id;
    this.capex.unitId = item.id;
  }

  selectCurrencyEvent(item: any): void {
    this.capex.currencyName = item.name;
    this.capex.currencyId = item.id;
  }

  cmbOutputChange() {
    this.selectedCapexUnit = 0;
    this.initialOutputName = '';
  }

  cmbCurrencyChange() {
    this.capex.currencyId = 0;
    this.initialCurrName = '';
  }

  async saveCapex(closed: boolean = false): Promise<void> {
    this.saving = true;
    let monto = this.capex.original.replace('$', '').replace(',', '').replace(',', '').replace(',', '');
    this.capex.originalAmount = Number.parseFloat(monto);
    this.purchaseServ.saveCapex(this.capex).subscribe(async () => {
      Swal.fire('¡Éxito!', 'Proveedor creado', 'success');
      this.saving = true;
      this.backClick();
    }, (error) => {
      Swal.fire('¡Error!', error.error, 'error');
      this.saving = true;
      return;
    });
  }

  backClick() {
    window.close();
  }

  onKeyPress(event: any) {
    var regex = new RegExp("^[0-9.]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  formatCurrency_OriginalAmount(event) {
    var uy = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(event.target.value);
    this.capex.original = uy;
  }

  formatCurrency_ReserveAmount(event) {
    var uy = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(event.target.value);
    this.capex.reserve = uy;
  }

  formatCurrency_ExecuteAmount(event) {
    var uy = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(event.target.value);
    this.capex.executed = uy;
  }

  formatCurrency_AvailableAmount(event) {
    var uy = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(event.target.value);
    this.capex.available = uy;
  }

  onFocus_OriginalAmount() {
    this.capex.original = '';
  }

  onFocus_ReserveAmount() {
    this.capex.reserve = '';
  }

  onFocus_ExecutedAmount() {
    this.capex.executed = '';
  }

  onFocus_AvailableAmount() {
    this.capex.available = '';
  }
}
